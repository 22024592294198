import queryString from 'query-string';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import reactUpdate from 'react-addons-update'
import _ from 'lodash';

import ItemPreviewSquare from './ItemPreviewSquare';
import LoadingSection from '../components/LoadingSection';
import PaginatedItems from '../components/PaginatedItems';


const ITEMS_PER_PAGE = 24;

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);

    const attribute_options = props.attribute === 'quantity_available' ? ['In stock', 'Arriving Soon', 'Last Chance'] : this.sortOptions(props.items.map((item) => item[props.attribute]));
    this.state = {
      attribute_options,
    }
  }

  sortOptions(options) {
    return _.sortBy(_.without(_.uniq(options), null, undefined, ''));
  }

  update_current_allowed_values = (allowed_value) => {
    if (allowed_value === null) {
      this.props.update_search_filter(null);
      return;
    }

    const {current_allowed_values} = this.props;
    if (!current_allowed_values) {
      this.props.update_search_filter([allowed_value]);
      return;
    }

    let new_values = current_allowed_values.slice();
    if (current_allowed_values.includes(allowed_value)) {
      new_values = _.remove(new_values, (value) => value !== allowed_value);
    } else {
      new_values.push(allowed_value);
    }

    this.props.update_search_filter(this.sortOptions(new_values))
  }

  toString(val) {
    if (typeof val === 'string') {
      return val;
    }
    if (typeof val === 'boolean') {
      if (val) {
        return 'True';
      }
      return 'False';
    }
  }

  render() {
    const {attribute_options} = this.state;
    const {attribute_display, current_allowed_values} = this.props;

    return (
      <div className="mr-2 mt-3">
        <h6>{attribute_display}</h6>
        <div className="btn-group">
          {attribute_options.map((attribute_option) => {
            const selected = current_allowed_values && current_allowed_values.includes(attribute_option);

            return (
              <button
                type="button"
                className={
                  classNames('btn', {'btn-info': selected, 'btn-light': !selected}, 'text-nowrap')
                }
                onClick={() => this.update_current_allowed_values(attribute_option)}
              >
                {
                  this.props.display_transform(this.toString(attribute_option))
                }
              </button>
            )
          })}
          <button
            type="button"
            className={
              classNames(
                'btn',
                {
                  'btn-info': !current_allowed_values || !current_allowed_values.length,
                  'btn-light': current_allowed_values && current_allowed_values.length,
                }
              )
            }
            onClick={() => this.update_current_allowed_values(null)}
          >
            Any
          </button>
        </div>
      </div>
    )
  }
}

SearchFilter.propTypes = {
  attribute_display: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  current_allowed_values: PropTypes.array.isRequired,
  update_search_filter: PropTypes.func.isRequired,
};

SearchFilter.defaultProps = {
  display_transform: (attribute) => attribute,
}

class ItemGrid extends React.Component {
  constructor(props) {
    super(props);

    const filter = this.props.filter || "";
    const filters = queryString.parse(decodeURIComponent(filter));
    let initial_search_filters = {
      quantity_available: filters['quantity_available'] ? filters['quantity_available'].split(',') : [],
      status: filters['status'] ? filters['status'].split(',') : [],
      is_contract_grade: filters['is_contract_grade'] ? 
        filters['is_contract_grade'].includes('true') ? [true] : filters['is_contract_grade'].includes('false') ? [false] : [] : [],
      product_group: filters['product_group'] ? filters['product_group'].split(',') : [],
    };

    this.state = {
      search_filters: initial_search_filters,
      show_filters: filter.includes("status") || filter.includes("is_contract_grade") || filter.includes("product_group")
    };
  }

  update_search_filter(attribute, allowed_values) {
    const search_filters = reactUpdate(this.state.search_filters, {$merge: {[attribute]: allowed_values}});
    this.setState({search_filters});
    if (this.props.update_filter) {
      this.props.update_filter(encodeURIComponent(Object.keys(search_filters).filter(
        function (k) {
          return (search_filters[k]) && (search_filters[k].length > 0);
        }
      ).map(
        function(k) {
          return encodeURIComponent(k) + '=' + encodeURIComponent(search_filters[k])
        }).join('&')
      ));
    }
  }

  render() {
    const {items: search_results, non_filter_items: all_items, collapse_color_variations} = this.props;
    let items = search_results;

    if (!items) {
      return <LoadingSection/>
    }

    const {search_filters} = this.state;
    _.each(search_filters, (allowed_values, attribute) => {
      if (!allowed_values || allowed_values.length === 0) {
        return;
      }

      if (attribute === 'quantity_available') {
        items = items.filter((item) => 
          (allowed_values.includes('In stock') && !item.closeout && item[attribute] > 0) ||
          (allowed_values.includes('Arriving Soon') && !item.closeout && item[attribute] === 0) ||
          (allowed_values.includes('Last Chance') && item.closeout && item[attribute] > 0));
      }
      else {
        items = items.filter((item) => allowed_values.includes(item[attribute]));
      }
    })

    if (collapse_color_variations) {
      const item_groups = _.groupBy(items, (item) => item.variation_color_sku || item.sku)

      items = _.uniqBy(items.map((item) => {
        const variations = item_groups[item.variation_color_sku || item.sku];
        const representative_item = (
        _.find(variations, ({main_image_s3_key}) => main_image_s3_key)
        || variations[0]);
        if (variations.length > 1) {
          representative_item.has_extra_colors = true
        }
        return representative_item
      }), 'sku')
    }

    // Types of filters
    // Status: Active, Special Order
    // Color: all types of color
    // Item Category!: Chair, Desk, etc
    // Material

    return (
      <div className="container">
        <div className="row">
            {this.props.allow_filters && (
              this.state.show_filters ? (
                  <div className="d-flex flex-wrap col-12">
                    <SearchFilter
                      attribute_display='Availability'
                      attribute='quantity_available'
                      items={all_items}
                      current_allowed_values={this.state.search_filters.quantity_available}
                      update_search_filter={(allowed_values) => this.update_search_filter('quantity_available', allowed_values)}
                    />

                    <SearchFilter
                      attribute_display='Status'
                      attribute='status'
                      items={all_items}
                      current_allowed_values={this.state.search_filters.status}
                      update_search_filter={(allowed_values) => this.update_search_filter('status', allowed_values)}
                    />

                    <SearchFilter
                      attribute_display='Contract Grade'
                      attribute='is_contract_grade'
                      items={all_items}
                      current_allowed_values={this.state.search_filters.is_contract_grade}
                      update_search_filter={
                        (allowed_values) => this.update_search_filter('is_contract_grade', allowed_values)}
                    />

                    <SearchFilter
                      attribute_display='Product Type'
                      attribute='product_group'
                      items={all_items}
                      current_allowed_values={this.state.search_filters.product_group}
                      update_search_filter={
                        (allowed_values) => this.update_search_filter('product_group', allowed_values)}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-nowrap col-12">
                    <SearchFilter
                      attribute_display='Availability'
                      attribute='quantity_available'
                      items={all_items}
                      current_allowed_values={this.state.search_filters.quantity_available}
                      update_search_filter={(allowed_values) => this.update_search_filter('quantity_available', allowed_values)}
                    />
                  </div>
                )
            )}
        </div>
        <div className="row mt-3">
            {this.props.allow_filters && (
              this.state.show_filters ? (
                    <button type="button" className="btn btn-link"
                      onClick={(e) => {
                        this.setState({show_filters: false});
                        e.preventDefault();
                      }}
                    >Hide Advanced Filters</button>
              ) : (
                  <button type="button" className="btn btn-link"
                    onClick={(e) => {
                      this.setState({show_filters: true});
                      e.preventDefault();
                    }}
                  >Advanced Filters</button>
              )
            )}
        </div>

        {!items && <div>No matching products.</div>}
        <PaginatedItems
          map_item_to_element={(item) =>
            <ItemPreviewSquare
              key={item.sku}
              item={item}
              has_extra_colors={item.has_extra_colors}
              show_color_variation={!collapse_color_variations}
            />
          }
          page={this.props.page}
          update_page={this.props.update_page}
          num_items_per_page={ITEMS_PER_PAGE}
          items={items}
        />

      </div>
    )
  }
}


ItemGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  collapse_color_variations: PropTypes.bool.isRequired,
  page: PropTypes.number,
  filter: PropTypes.string,
  update_page: PropTypes.func,
  update_filter: PropTypes.func,
}

ItemGrid.defaultProps = {
  collapse_color_variations: true,
}


export default ItemGrid;