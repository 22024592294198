import React from 'react';

import CustomPage from '../components/CustomPage';

class MAPPolicy extends React.Component {
  render() {
    return <CustomPage page={'accessibility'}/>;
  }
}

export default MAPPolicy;