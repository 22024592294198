import axios from 'axios';
import React from 'react';

import dao from '../dao';
import ItemGrid from '../product/ItemGrid';
import CustomPage from '../components/CustomPage';

import './contentsio.css';


class FeaturedProductsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {items: null};
  }

  async componentDidMount() {
    const items = await dao.getItems({is_new: true});
    const items_with_images = items.filter(({main_image_s3_key}) => main_image_s3_key);

    this.setState({items: items_with_images});
  }

  render() {
    const {items} = this.state;

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>New Products</h3>
            </div>
          </div>
        </div>

        <ItemGrid items={items} non_filter_items={items}/>
      </div>
    )
  }
}


class PublicHome extends React.Component {
  constructor (props) {
    super(props);
  }

  render() {
    return (
      <div>
        <CustomPage page="home-page" />
        <FeaturedProductsSection />
      </div>
    );
  }
}

export default PublicHome;
