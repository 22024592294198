import React from 'react';
import BaseSelect from 'react-select';
import DatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone';

import dao from '../dao';
import UserStore from '../auth/UserStore';
import FixRequiredSelect from '../auth/FixRequiredSelect';
import LoadingSection from '../components/LoadingSection';
import FourOhFourPage from '../one_off_pages/404Page';

import "react-datepicker/dist/react-datepicker.css";

const dropstyle = {height: 100, borderWidth: 2, borderColor: '#666666', borderStyle: 'dashed', borderRadius: 5, margin: 5};
const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

class ClaimForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.get_user(),
      files: [],
      is_loading: false,
      date: new Date(),
      damage: [{value: 'freight_damage', label: 'Freight Damage'},{value: 'shortage', label: 'Shortage'},
        {value: 'concealed_damage', label: 'Concealed Damage'},{value: 'manufacture_defects', label: 'Manufacture Defects'}],
      tracking_no: '',
      so_number: '',
      po_number: '',
      damage_description: '',
      delivery_date: null,
      noted_damage: 'No',
      repaired_locally: 'No',
      repair_estimate: '',
      item_description_1: '',
      qty_description_1: '',
      item_description_2: '',
      qty_description_2: '',
      item_description_3: '',
      qty_description_3: '',
      email: '',
      name: '',
      phone_no: ''
    };
  }

  componentDidMount() {
    UserStore.on('change', this.setUserFromStore);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserFromStore);
  }

  setUserFromStore = () => {
    this.setState({user: UserStore.get_user()});
  }

  onDrop = (newfiles) => {
    this.setState({files: this.state.files.concat(newfiles)});
  }

  setGroups = (e) => {
    var list = "";
    if (e) {
      e.forEach(element => list += element.value + ';');
    }
    this.setState({groups: list});
  }

  send_form = async (e) => {
    e.preventDefault();
    this.setState({is_loading: true});
    const request_data = Object.assign(this.state);
    await dao.requestClaim(this.state.files, JSON.stringify(request_data));
    window.location = '/requests/success';
  }

  deleteFile = (i) => {
    this.state.files.splice(i, 1);
    this.setState({files: this.state.files});
  }

  render() {
    const {is_loading, user} = this.state;

    if (!user && UserStore.get_waiting_for_response())
      return <LoadingSection/>;

    if (!user && !UserStore.get_waiting_for_response())
      return <FourOhFourPage/>;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
            <h2>Claim Form</h2>
            <form onSubmit={this.send_form}>
              <div className="form-row">
                <div className="form-group col-12">
                  <label style={{ fontWeight: 'bold' }}>Claim Form is only applicable to purchases within the warranty period. Our Residential products have a 2-year warranty.
                  Contract products have a 3-year warranty.<br /><br />Freight claims should be reported within 5 days of receipt of goods.</label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-4">
                  <label>Date<b style={{color: "red"}}> *</b></label>
                  <DatePicker
                    className="form-control"
                    name="date"
                    selected={this.state.date}
                    onChange={value => this.setState({ date: value})}
                    required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>Damage<b style={{color: "red"}}> *</b></label>
                  <Select
                    closeMenuOnSelect={true}
                    isMulti
                    options={this.state.damage}
                    onChange={this.setGroups}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>Was damage/shortage noted at time or delivery</label>
                  <SelectField
                    name="noted_damage"
                    value={this.state.noted_damage}
                    updateValue={value => this.setState({ noted_damage: value})}
                    options={['No', 'Yes']} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Carrier Tracking #</label>
                  <input
                    className="form-control"
                    name="tracking_no"
                    value={this.state.tracking_no}
                    onChange={e => this.setState({ tracking_no: e.target.value})} />
                </div>
                <div className="form-group col-6">
                  <label>Delivery Date</label>
                  <DatePicker
                    className="form-control"
                    name="delivery_date"
                    selected={this.state.delivery_date}
                    onChange={value => this.setState({ delivery_date: value})} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Sales Order Number<b style={{color: "red"}}> *</b></label>
                  <div style={{position: 'relative'}}>
                    <input
                      className="form-control"
                      style={{paddingLeft: 38}}
                      type="number"
                      name="so_number"
                      value={this.state.so_number}
                      onChange={e => this.setState({ so_number: e.target.value})}
                      required />
                    <span style={{ position: 'absolute', display: 'block', left: '0.75rem', top: 7, zIndex: 9}}>SO</span>
                  </div>
                </div>
                <div className="form-group col-6">
                  <label>PO Number</label>
                  <input
                    className="form-control"
                    name="po_number"
                    value={this.state.po_number}
                    onChange={e => this.setState({ po_number: e.target.value})} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>Container Reference Number<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="container_ref_number"
                    value={this.state.container_ref_number}
                    onChange={e => this.setState({ container_ref_number: e.target.value})}
                    required />
                  <label>(Can be found on a sticker underneath the product or on the cartons.
                    It consists of 3-4 letters followed by 3 digits.)</label>
                </div>
              </div>
              <div className="form-row">
                <label className="col-10">Item, style, and description</label>
                <label className="col-2">Qty</label>
              </div>
              <div className="form-row">
                <div className="col-10">
                  <input
                    className="form-control"
                    name="item_description_1"
                    value={this.state.item_description_1}
                    onChange={e => this.setState({ item_description_1: e.target.value})}
                    required />
                </div>
                <div className="col-2">
                  <input
                    className="form-control"
                    name="qty_description_1"
                    value={this.state.qty_description_1}
                    onChange={e => this.setState({ qty_description_1: e.target.value})}
                    required />
                </div>
              </div>
              <div className="form-row mt-2">
                <div className="col-10">
                  <input
                    className="form-control"
                    name="item_description_2"
                    value={this.state.item_description_2}
                    onChange={e => this.setState({ item_description_2: e.target.value})}
                    />
                </div>
                <div className="col-2">
                  <input
                    className="form-control"
                    name="qty_description_2"
                    value={this.state.qty_description_2}
                    onChange={e => this.setState({ qty_description_2: e.target.value})}
                    />
                </div>
              </div>
              <div className="form-row mt-2">
                <div className="col-10">
                  <input
                    className="form-control"
                    name="item_description_3"
                    value={this.state.item_description_3}
                    onChange={e => this.setState({ item_description_3: e.target.value})}
                    />
                </div>
                <div className="col-2">
                  <input
                    className="form-control"
                    name="qty_description_3"
                    value={this.state.qty_description_3}
                    onChange={e => this.setState({ qty_description_3: e.target.value})}
                    />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="form-group col-12">
                  <label>Please provide specific description of damage to the item as well as the condition of the carton<b style={{color: "red"}}> *</b></label>
                  <textarea
                    className="form-control"
                    name="damage_description"
                    value={this.state.damage_description}
                    onChange={e => this.setState({ damage_description: e.target.value})}
                    rows="4"
                    required />
                  <label>(Please keep all cartons and internal packing)</label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Can it be repaired locally</label>
                  <SelectField
                    name="repaired_locally"
                    value={this.state.repaired_locally}
                    updateValue={value => this.setState({ repaired_locally: value})}
                    options={['No', 'Yes']}
                    required />
                </div>
                <div className="form-group col-6">
                  <label>Repair Estimate ($){this.state.repaired_locally === 'Yes' && <b style={{color: "red"}}> *</b>}</label>
                  <input
                    className="form-control"
                    name="repair_estimate"
                    value={this.state.repair_estimate}
                    onChange={e => this.setState({ repair_estimate: e.target.value})}
                    required={this.state.repaired_locally === 'Yes'} />
                  <label>(include copy)</label>
                </div>
              </div>
              <div className="form-row">
              <div className="form-group col-6">
              <label>Pictures of damaged item(s) and carton/wrapping</label>
                <div className="dropzone">
                  <Dropzone
                    accept="image/jpeg, image/png"
                    onDrop={this.onDrop.bind(this)}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div style={dropstyle} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p style={{padding: 5}}>Drop files here, or click to select files to upload.</p>
                      </div>
                    )}
                  </Dropzone>
                  <ul style={{listStyleType: 'none', margin: 0, padding: 0}}>
                    { this.state.files.map((f, i) => (
                      <li key={i}>{f.name}<span style={{color: 'red', fontWeight: 'bold', cursor: 'pointer'}} onClick={() => this.deleteFile(i)}> x</span></li>)
                      ) }
                  </ul>
                </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-12">
                  <label>Email address of person filing claim<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value})}
                    required />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col-6">
                  <label>Name</label>
                  <input
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value})}
                    />
                </div>
                <div className="col-6">
                  <label>Phone number</label>
                  <input
                    className="form-control"
                    name="phone_no"
                    value={this.state.phone_no}
                    onChange={e => this.setState({ phone_no: e.target.value})}
                    />
                </div>
              </div>
              <div className="form-row mt-3">
                <label style={{ fontWeight: 'bold' }}>Claims should be reported within 5 days of receipt of goods.</label>
              </div>
              <button className="btn btn-success mt-3" type="submit" disabled={is_loading}>Submit Claim</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const SelectField = ({fieldKey, value, updateValue, required, options, disabled}) => {
  return (
    <select
      className="form-control"
      name={fieldKey}
      required={required}
      onChange={(e) => updateValue(e.target.value)}
      value={value}
      disabled={disabled}
    >
      {options.map((option, index) => {
        let display, optionValue;
        if (typeof option === 'string') {
          display = option;
          optionValue = option;
        } else {
          display = option.display;
          optionValue = option.value;
        }
        return (
          <option key={index} value={optionValue}>{display}</option>
        )
      })}
    </select>
  );
};

export default ClaimForm;
