import moment from 'moment';
import _ from 'lodash';

const S3_BUCKET_URL = 'https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/';

const util = {};

util.getS3Url = (s3_key, {size} = {size: null}) => {
  let s3_url = `${S3_BUCKET_URL}${s3_key}`;

  if (size) {
    s3_url += `-${size}`;
  }
  return s3_url;
};

util.choose_random_from_array = (array) => array[_.random(array.length - 1)];

const ITEM_SEARCH_FIELDS = [
  'sku',
  'product_description',
  'description_es',
  'description_ad',
];

util.filter_object_by_fields = (candidate, search_term, search_fields) => {
  if (search_term.trim() === '') {
    return true;
  }

  const upper_search_terms = search_term.toUpperCase().split(' ').filter((term) => term);

  for (const field of search_fields) {
    let field_value = candidate[field];

    if (!field_value) {
      continue;
    }

    if (Number.isInteger(field_value)) {
      field_value = field_value.toString();
    }

    const upper_field_value = field_value.toUpperCase();

    const is_query_a_match = upper_search_terms.every((upper_search_term) => (
      upper_field_value.includes(upper_search_term)
    ));

    if (is_query_a_match) {
      return true;
    }
  }

  return false;
}

util.filter_item_description = (item, search_term) =>
  util.filter_object_by_fields(item, search_term, ITEM_SEARCH_FIELDS);

util.PRICE_GROUP_TO_PRICE_LEVEL = {
  MSRP: 'price_msrp',
  MAP: 'price_map',
  EMAP: 'price_new_ecommerce_map',
  EWHOLE: 'price_new_ecommerce_wholesale',
  NP: 'price_np',
  D: 'price_d',
  C: 'price_c',
  CA: 'price_ca',
  H: 'price_h',
  HA: 'price_ha',
  H18: 'price_h18',
  K: 'price_k',
  KA: 'price_ka',
  K18: 'price_k18',
  I: 'price_wholesale',
  W: 'price_w',
  L: 'price_l',
  T: 'price_t',
  R: 'price_r',
  U: 'price_u',
  'P4.5': 'price_p45',
  P4: 'price_p40',
  'P3.5': 'price_p35',
  P3: 'price_p30',
  'P2.5': 'price_p25',
  P2: 'price_p20',
  PRB: 'price_prb',
  FC: 'price_fc',
  FC5: 'price_fc5',
  FC10: 'price_fc10',
  FC15: 'price_fc15',
  FC20: 'price_fc20',
  FC25: 'price_fc25',
  CF: 'price_cf',
}

util.ORIG_PRICE_GROUP_TO_PRICE_LEVEL = {
  MSRP: 'orig_price_msrp',
  MAP: 'orig_price_map',
  EMAP: 'orig_price_new_ecommerce_map',
  EWHOLE: 'orig_price_new_ecommerce_wholesale',
  NP: 'orig_price_np',
  D: 'orig_price_d',
  C: 'orig_price_c',
  CA: 'orig_price_ca',
  H: 'orig_price_h',
  HA: 'orig_price_ha',
  H18: 'orig_price_h18',
  K: 'orig_price_k',
  KA: 'orig_price_ka',
  K18: 'orig_price_k18',
  I: 'orig_price_wholesale',
  W: 'orig_price_w',
  L: 'orig_price_l',
  T: 'orig_price_t',
  R: 'orig_price_r',
  U: 'orig_price_u',
  'P4.5': 'orig_price_p45',
  P4: 'orig_price_p40',
  'P3.5': 'orig_price_p35',
  P3: 'orig_price_p30',
  'P2.5': 'orig_price_p25',
  P2: 'orig_price_p20',
  PRB: 'orig_price_prb',
  FC: 'orig_price_fc',
  FC5: 'orig_price_fc5',
  FC10: 'orig_price_fc10',
  FC15: 'orig_price_fc15',
  FC20: 'orig_price_fc20',
  FC25: 'orig_price_fc25',
  CF: 'orig_price_cf',
}

util.PRICE_LEVEL_TO_PRICE_GROUP = _.invert(util.PRICE_GROUP_TO_PRICE_LEVEL);

util.date_format_iso_to_day = (iso_string)  => moment(iso_string).format('MMM DD, YYYY');


export default util;
