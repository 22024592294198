import React from 'react';
import moment from 'moment';

import UserStore from '../auth/UserStore';
import DescriptionList from '../components/DescriptionList';
import LoadingSection from '../components/LoadingSection';

import dao from '../dao';
import CustomerDAO from '../customers/CustomerDAO';

class CustomerUserCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getUserState();
      }
    
      componentDidMount() {
        UserStore.on('change', this.setUserState);
      }
    
      componentWillUnmount() {
        UserStore.removeListener('change', this.setUserState);
      }
    
      getUserState = () => {
        return {
          customer: UserStore.get_customer()
        };
      }
    
      setUserState = () => {
        this.setState(this.getUserState());
      }
    
      render() {
        const {customer} = this.state;

        if (!customer)
            return <LoadingSection/>;

        return (
          <div className="container">
            <div className="row">
                <h3>Customer Info</h3>

                <DescriptionList
                className='mb-3'
                description_schemas={[
                    {label: 'Name', attribute: 'name'},
                    {label: 'Category', attribute: 'category'},
                    {label: 'Price Level', attribute: 'price_group'},
                    {label: 'Customer Service', attribute: 'customer_service_code'},
                    {label: 'Sales Rep', attribute: 'sales_person_code'},
                    {label: 'Primary Email', attribute: 'email'},
                    {
                    label: 'Website',
                    render_func: ({website}) => website ? <a href={website} target="_blank" rel="noopener noreferrer">{website}</a> : null
                    },
                    {label: 'Phone Number', attribute: 'phone_number'},
                    {
                    label: 'Date Opened',
                    render_func: ({created_at}) => moment(created_at).format('MMMM, DD YYYY')
                    },
                    {label: 'Payment Terms', attribute: 'payment_terms_code'},
                ]}
                data={customer}
                />
            </div>
            <UsersList customer_id={customer.id}/>
          </div>
        );
    }
}


class UsersList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        users: null,
        is_loading: true,
      };
    }
  
    componentDidMount = async() => {
      const {users} = await CustomerDAO.get_users(this.props.customer_id);
      this.setState({users, is_loading: false});
    }
  
    render() {
      const {is_loading, users} = this.state;
  
      if (is_loading) {
        return <div className='row'>Loading users...</div>
      }
  
      return (
        <div className='row'>
          <div className='col-12'>
            <h3>Users</h3>
  
            <NewUserForm />
  
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Title</th>
                  <th>Last Visit</th>
                </tr>
  
              </thead>
  
              <tbody>
                {users.map((user) => {
                  return (
                    <tr>
                      <td>{user.email}</td>
                      <td>{user.company_title}</td>
                      <td>{user.last_visit && (moment(user.last_visit).format('MM/DD/YYYY'))}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
}

class NewUserForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user_name: null,
        text: null,
      };
    }

  handleSubmit = async(e) => {
    e.preventDefault();
    const {user_name} = this.state;
    const text = await dao.createUserRequest(user_name);
    this.setState({text});
  }

  render() {
    const {text} = this.state;
    if (text)
      return (
        <div>
          {text && text.text && (
            <div class="alert alert-success" role="alert">{text.text}</div>
          )}
          {text && text.error && (
            <div class="alert alert-danger" role="alert">{text.error}</div>
          )}
        </div>
      )

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label className="form-check-label">Enter new user's email:
            <input 
              type="email"
              required
              className="form-control"
              onChange={(e) => this.setState({user_name: e.target.value})}
            />
          </label>
          <button type="submit" className="btn btn-success ml-2">Send request</button>
        </div>
      </form>
    )
  }
}


export default CustomerUserCard;