import {Link} from 'react-router-dom';
import React from 'react';

import dao from '../dao';
import {FormInput, get_initial_state_from_field_definitions} from './FormInput';
import {EMPTY_SHIPPING_ADDRESS} from '../components/AddressFields';

const CATALOG_REQUEST_FIELDS = [
  {
    key: 'company_name',
    display: 'Company Name',
    type: 'text',
    required: true,
  },
  {
    key: 'email',
    display: 'Your Email',
    type: 'email',
    required: true,
  },
  {
    key: 'company_website',
    display: 'Company Website',
    type: 'text',
  },
  /*{
    key: 'catalog_size',
    display: 'Catalog Size',
    type: 'catalog',
    initial_value: 'Large',
    required: true,
  },*/
  {
    key: 'how_did_you_hear_about_us',
    display: 'How did you hear about us?',
    type: 'textarea',
    required: true,
  },
  {
    key: 'notes',
    display: 'Request Notes',
    type: 'textarea',
  },
  {
    key: 'shipping_address',
    display: 'Shipping Address',
    type: 'address',
    initial_value: EMPTY_SHIPPING_ADDRESS,
    large: true,
  },
];

class CatalogRequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = get_initial_state_from_field_definitions(CATALOG_REQUEST_FIELDS);
  }

  requestCatalog = async(e) => {
    e.preventDefault();
    const {shipping_address} = this.state;

    const catalog_request_data = Object.assign({...shipping_address}, this.state);
    catalog_request_data.catalog_size = 'Large';
    if (catalog_request_data.catalog_size !== '') {
      await dao.createCatalogRequest(catalog_request_data);
      window.location = '/requests/success';
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
            <h2>Request a <span className="bold">Catalog</span></h2>

            <p>
              We have a <Link to="/downloads">digital version of our catalog on this site</Link>. And you can
              also browse our products here. But if you would like a physical copy, fill out the form below!
            </p>

            <p>
              If you are a furniture retailer, designer, or contract furniture buyer, please fill out this
              form if you’d like to receive a printed copy of our latest catalog.
            </p>

            <form onSubmit={this.requestCatalog}>
              <div className="row">

                {CATALOG_REQUEST_FIELDS.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => this.setState({[request_field.key]: new_value})}
                    value={this.state[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}

              </div>

              <button className="btn btn-success" type="submit">
                Request Catalog
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default CatalogRequestForm;