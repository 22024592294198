import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import {Link, Route} from 'react-router-dom';
import _ from 'lodash';
import Markdown from 'react-markdown';


import CustomerDAO from './CustomerDAO';
import {CustomerComments} from './CustomerComments';
import CustomerSalesByMonth from './CustomerSalesByMonth';
import CustomerUtil from './CustomerUtil';
import LoadingSection from '../components/LoadingSection';
import Price from '../components/Price';
import Percent from '../components/Percent';
import OrderTable from '../orders/OrderTable';
import {SalesOrderTableWrapper} from '../sales_orders/SalesOrderTable';
import {AddCardViaStripeCheckout, CustomerCardsTable} from '../pay/PaymentForm';
import util from '../util';
import {ResaleCertificateSection} from '../profile/ResaleCertificate';
import TermsAndConditionsDoc from '../profile/TermsAndConditionsDoc';

const ThCenter = ({children}) => <th className="text-center">{children}</th>


class CustomerUsersSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      users: null,
    }
  }

  componentDidMount = async() => {
    const {customer_id} = this.props;
    const {users} = await CustomerDAO.get_users(customer_id);
    this.setState({is_loading: false, users})
  }

  render() {
    const {users, is_loading} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    return (
      <div>
        <h4>Signed Up Users</h4>

        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <ThCenter>ID</ThCenter>
              <ThCenter>Email</ThCenter>
              <ThCenter>First</ThCenter>
              <ThCenter>Last</ThCenter>
              <ThCenter>Title</ThCenter>
              <ThCenter>Phone</ThCenter>
              <ThCenter>Signed Up</ThCenter>
            </tr>
          </thead>
          <tbody>
            {users.map(({id, email, first_name, last_name, company_title, phone_number, created_at}) => (
              <tr>
                <td><Link to={`/internal/users/${id}`}>{id}</Link></td>
                <td>{email}</td>
                <td>{first_name}</td>
                <td>{last_name}</td>
                <td>{company_title}</td>
                <td>{phone_number}</td>
                <td>{util.date_format_iso_to_day(created_at)}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    )
  }
}

CustomerUsersSection.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

const get_total = (item_infos, attribute) => (
  _.chain(item_infos)
    .map((item_info) => item_info[attribute])
    .reduce((a, b) => a + b, 0)
    .value()
)

class CustomerBestItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      best_item_infos: null,
      is_loading: true,
    }
  }

  componentDidMount = async() => {
    const {customer_id} = this.props;
    const {best_item_infos} = await CustomerDAO.get_best_items(customer_id)
    this.setState({best_item_infos, is_loading: false});
  }

  download_best_items_report = async() => {
    const {customer_id} = this.props;
    const {s3_key} = await CustomerDAO.get_best_items_report(customer_id)

    const s3_url = util.getS3Url(s3_key);
    window.open(s3_url);
  }


  render() {
    const {is_loading, best_item_infos} = this.state;
    if (is_loading) {
      return <div>Loading best items...</div>;
    }

    return (
      <div>
        <h3>Best Items</h3>

        <p>
          This shows the top 20 best items for the customer in the last 365 days.
        </p>

        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th/>
              <ThCenter>SKU</ThCenter>
              <ThCenter>Name</ThCenter>
              <ThCenter>Total Units</ThCenter>
              <ThCenter>% of Customer Units</ThCenter>
              <ThCenter>Total Amount $</ThCenter>
              <ThCenter>% of Customer $</ThCenter>
            </tr>
          </thead>
          <tbody>
            {best_item_infos.map(({item, percent_of_amount, percent_of_items, total_amount, total_items}) => (
              <tr>
                <td className="align-middle">
                  <img
                    src={util.getS3Url(item.main_image_s3_key + '-thumb')}
                    style={{
                      maxWidth: 80
                    }}
                    alt='Missing info'/>
                </td>
                <td className="align-middle"><Link to={`/products/${item.sku}`}>{item.sku}</Link></td>
                <td className="align-middle">{item.product_name || item.description_es}</td>
                <td className="text-right align-middle">{total_items}</td>
                <td className="text-right align-middle"><Percent percent={percent_of_items}/></td>
                <td className="text-right align-middle"><Price price={total_amount} num_digits={0}/></td>
                <td className="text-right align-middle"><Percent percent={percent_of_amount} num_digits={1}/>
                </td>
              </tr>
            ))}
          </tbody>

          <tfoot className="table-info font-weight-bold">
            <tr>
              <td />
              <td />
              <td />
              <td className="text-right align-middle">
                {get_total(best_item_infos, 'total_items')}
              </td>
              <td className="text-right align-middle">
                <Percent percent={get_total(best_item_infos, 'percent_of_items')} num_digits={1}/>
              </td>
              <td className="text-right align-middle">
                <Price price={get_total(best_item_infos, 'total_amount')}/>
              </td>
              <td className="text-right align-middle">
                <Percent percent={get_total(best_item_infos, 'percent_of_amount')} num_digits={1}/>
              </td>
            </tr>
          </tfoot>
        </table>

        <div>
          <button type="button" className="btn btn-link"
            onClick={this.download_best_items_report}>
            Download Best Items in Last Year
          </button>
        </div>
      </div>
    )
  }
}

CustomerBestItems.propTypes = {
  customer_id: PropTypes.string.isRequired,
}

const CUSTOMER_ATTRIBUTE_TO_DISPLAY = {
  ID: 'id',
  Category: 'category',
  'Price Level': 'price_group',

  'Sales Person': 'sales_person_code',
  'Customer Service': 'customer_service_code',
  'Sales Person 2': 'sales_person_code_2',

  Email: ({email}) => email ? <a href={`mailto-frontapp:${email}`}>{email}</a> : null,
  Website: ({website}) => website ? <a href={website} target="_blank" rel="noopener noreferrer">{website}</a> : null,
  'Primary Contact': 'primary_contact_name',
  'Phone Number': 'phone_number',
  'Address 1': 'address_1',
  'Address 2': 'address_2',
  City: 'city',
  State: 'state',
  'Zip Code': 'zip_code',

  'Date Opened': ({created_at}) => moment(created_at).format('MMMM, DD YYYY'),
  Sales: (customer) => <Price price={customer.historical_sales_amount}/>,
  'Order Count': 'historical_order_count',
  'Payment Terms': 'payment_terms_code',

  'Avg Order': (customer) =>
    <Price price={customer.historical_order_count === 0 ? 0 : customer.historical_sales_amount / customer.historical_order_count}/>,
  'Account Status': 'account_status',
};

const RowColumnWrapper = ({children}) => (
  <div className="row">
    <div className="col-12 col-md-8 col-lg-6">
      {children}
    </div>
  </div>
)

class CustomerNotesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      is_editing: false,

      new_customer_notes: this.props.customer_notes || '',
    }
  }

  save_customer_notes = async(e) => {
    e.preventDefault();

    this.setState({is_loading: true});
    const {customer_id} = this.props;
    const {new_customer_notes} = this.state;

    await CustomerDAO.update_customer(customer_id, {notes: new_customer_notes})
    window.location.reload()
  }

  render() {
    const {customer_notes} = this.props;
    const {is_loading, is_editing, new_customer_notes} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    if (is_editing) {
      return (
        <RowColumnWrapper>
          <h3>Notes</h3>
          <button type="button" className="btn btn-link" onClick={() => this.setState({is_editing: false})}>Cancel</button>

          <form onSubmit={this.save_customer_notes}>
            <textarea
              className="form-control mb-2 w-100"
              rows="10"
              onChange={(e) => this.setState({new_customer_notes: e.target.value})}
              value={new_customer_notes}
            />

            <button className="btn btn-success">Save</button>
          </form>
        </RowColumnWrapper>
      )
    }

    return (
      <RowColumnWrapper>
        <h3>Notes</h3>
        <button type="button" className="btn btn-link" onClick={() => this.setState({is_editing: true})}>Edit</button>
        <Markdown
          source={customer_notes}
          renderers={{
            heading: (props) => (
              React.createElement(`h${props.level}`, props,
                <span className="markdown-header">{props.children}</span>
              )
            )
          }}
        />
      </RowColumnWrapper>
    )
  }
}

CustomerNotesSection.propTypes = {}

const get_google_maps_link_from_customer = ({address_1, city, state, zip_code}) => (
  `https://maps.google.com/?q=${address_1} ${city} ${state} ${zip_code}`
)

class MergeCustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav_customer_id: '',
    };
  }

  merge_customers = async(e) => {
    e.preventDefault();

    const {customer_to_delete_id} = this.props;
    const {nav_customer_id} = this.state;

    await CustomerDAO.merge_customers({
      customer_to_delete_id: customer_to_delete_id,
      customer_to_persist_id: nav_customer_id,
    })

    window.location = `/internal/customers/${nav_customer_id}`
  }

  render() {
    const {nav_customer_id} = this.state;

    return (
      <div>
        <h3>Merge with NAV Customer</h3>
        <p>
          This customer will be deleted and all orders, comments, users will point to the NAV customer.
        </p>

        <form onSubmit={this.merge_customers}>
          <div>
            <label>NAV Customer ID</label>
            <input
              className="form-control"
              type='tel'
              value={nav_customer_id}
              onChange={(e) => this.setState({nav_customer_id: e.target.value})}
            />
          </div>

          <button className="btn btn-success">
            Merge
          </button>
        </form>
      </div>
    )
  }
}

MergeCustomerForm.propTypes = {
  customer_to_delete_id: PropTypes.number.isRequired,
}

const CustomerInfoSection = ({customer, editable}) => (
  <div>
    <dl className="row mb-5">
      {_.map(CUSTOMER_ATTRIBUTE_TO_DISPLAY, (attribute, display, idx) => [
        <dt className="col-3 text-right" key={`display-${idx}`}>{display}</dt>,
        <dd className="col-3" key={`value-${idx}`}>{
          typeof attribute === 'string' ? customer[attribute] : attribute(customer)
        }</dd>
      ])}

      <dt className="col-3 text-right">Stripe</dt>
      <dd className="col-3">
        {customer.stripe_customer_id && (
          <a href={`https://dashboard.stripe.com/customers/${customer.stripe_customer_id}`} target="_blank" rel="noopener noreferrer">
            Profile
          </a>
        )}
      </dd>

    </dl>
    <dl className="row">
      <div className="col">
        <a href={get_google_maps_link_from_customer(customer)} target="_blank" rel="noopener noreferrer">
          Google Maps of Address
        </a>
      </div>
    </dl>

    <div className="mb-4">
      <Link
        to="/"
        onClick={() => {
          CustomerUtil.set_current_customer(customer)
          this.props.history.push('/');
        }}
      >
        Set as current customer
      </Link>
    </div>

    <CustomerNotesSection customer_id={customer.id} customer_notes={customer.notes}/>

    {customer.id > 100000 && (
      <div>
        <hr />
        <MergeCustomerForm customer_to_delete_id={customer.id}/>
      </div>
    )}

    {editable && (<>
      <hr />

      <div className="row">
        <ResaleCertificateSection customer_id={customer.id}/>
        <TermsAndConditionsDoc customer_id={customer.id}/>
      </div>
    </>)}
  </div>

)

class CustomerOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {orders: null};
  }

  componentDidMount = async() => {
    const {customer_id} = this.props;
    const {orders} = await CustomerDAO.get_orders(customer_id);
    this.setState({orders});
  }

  render() {
    const {orders} = this.state;
    return (
      <div>
        <h4>Orders Submitted on euro.style</h4>
        <OrderTable orders={orders}/>
      </div>
    )
  }
}


const CUSTOMER_OVERVIEW_ROUTE = '/overview';
const CUSTOMER_INFO_TABS = [
  {route: CUSTOMER_OVERVIEW_ROUTE, display: 'Overview'},
  {route: '/comments', display: 'Comments'},
  {route: '/stats', display: 'Sales Stats'},
  {route: '/orders', display: 'Orders'},
  {route: '/users', display: 'Users'},
  {route: '/credit-cards', display: 'Credit Cards'},
];

const CUSTOMER_INFO_ROUTES = CUSTOMER_INFO_TABS.map(({route}) => route);


class CustomerInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
    };
  }

  async componentDidMount() {
    const {customer_id} = this.props.match.params;

    const customer = await CustomerUtil.get_customer(parseInt(customer_id, 10));
    this.setState({customer});


    // Update tab route
    const {match, history} = this.props;
    const {url: match_url} = match;
    const {replace} = history;

    const current_tab_route = this.get_current_tab_route();

    if (!current_tab_route || !CUSTOMER_INFO_ROUTES.includes(current_tab_route)) {
      replace(`${match_url}${CUSTOMER_OVERVIEW_ROUTE}`)
    }
  }

  get_current_tab_route() {
    const {match, history} = this.props;
    const {location} = history;
    const {url: match_url} = match;

    const current_tab_regexp = new RegExp(match_url + '(.*)');
    const regexp_match = current_tab_regexp.exec(location.pathname);

    if (!regexp_match) {
      return null;
    }

    return regexp_match[1];
  }


  render() {
    const {customer} = this.state;
    const {match} = this.props;

    if (!customer)
      return <LoadingSection/>;

    const current_tab_route = this.get_current_tab_route();

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link to={this.props.editable ? "/internal/customers" : "/customers"}>Customer List</Link>
            <h1 className="mb-2">Customer <span className="bold">{customer.name}</span></h1>

            <ul className="nav nav-pills justify-content-center mb-3">
              {CUSTOMER_INFO_TABS.map(({route, display}, idx) => (
                <li className='nav-item' key={idx}>
                  <Link className={classNames('nav-link', {active: route === current_tab_route})}
                        to={`${match.url}${route}`}>
                    {display}
                  </Link>
                </li>
              ))}
            </ul>

            <Route
              path={`${match.url}${CUSTOMER_OVERVIEW_ROUTE}`}
              render={() => <CustomerInfoSection customer={customer} editable={this.props.editable}/>}
            />

            <Route
              path={`${match.url}/comments`}
              render={() => <CustomerComments customer_id={customer.id}/>}
            />

            {this.props.editable && (
              <Route
                path={`${match.url}/stats`}
                render={() => (
                  <div>
                    <CustomerBestItems customer_id={customer.id}/>

                    <hr className="my-5"/>

                    <CustomerSalesByMonth customer_id={customer.id}/>
                  </div>
                )}
              />
            )}

            <Route
              path={`${match.url}/orders`}
              render={() => (
                <div>
                  <CustomerOrders customer_id={customer.id}/>

                  <hr className="my-5"/>

                  <div>
                    <h4>All Sales Orders</h4>
                    <SalesOrderTableWrapper customer_id={customer.id}/>
                  </div>
                </div>
              )}
            />

            {this.props.editable && (<>
              <Route
                path={`${match.url}/users`}
                render={() => <CustomerUsersSection customer_id={customer.id}/>}
              />

              <Route
                path={`${match.url}/credit-cards`}
                render={() => (
                  <div>
                    <CustomerCardsTable customer_id={customer.id}/>

                    <AddCardViaStripeCheckout customer_id={customer.id}/>
                  </div>)
                }
              />
            </>)}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerInfoPage;
