import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import BaseSelect from 'react-select';

import FixRequiredSelect from "../auth/FixRequiredSelect";

const EMPTY_SHIPPING_ADDRESS = {
  name: '',
  phone_number: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip_code: '',
  country: 'US',
};


const countries = [
  { value: 'DZ', label: 'Algeria' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BR', label: 'Brazil' },
  { value: 'VG', label: 'British Virgin Islands' },
  { value: 'CA', label: 'Canada' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CL', label: 'Chile' },
  { value: 'CO', label: 'Colombia' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CW', label: 'Cura�ao' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DO', label: 'Dominica Rep' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'FR', label: 'France' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IN', label: 'India' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NO', label: 'Norway' },
  { value: 'PA', label: 'Panama' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SR', label: 'Suriname' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VI', label: 'Virgin Islands, US' }
];

const us_states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

const canada_states = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon Territory' }
];

const Select = props => (
  <div className={classNames('form-group', {'col-6': !props.is_big, 'col-12': props.is_big})}>
    <label>{props.label}{props.required && <b style={{color: "red"}}> *</b>}</label>
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.list}
    />
  </div>
);

/*const loader = new Loader({
  apiKey: 'AIzaSyBjtFDG0r2t95c4UWVdD30F2XmlqgRRAfw',
  libraries: ['places']
});*/

class AddressField extends React.Component {
  render() {
    const {value, onChange, label, is_big, disabled, max_length, required} = this.props;
    return (
      <div className={classNames('form-group', {'col-6': !is_big, 'col-12': is_big})}>
        <label>{label}{required && <b style={{color: "red"}}> *</b>}</label>
        <input
          maxLength={max_length}
          type="text"
          className="form-control"
          name="form-control"
          onChange={onChange}
          value={value}
          disabled={disabled}
          ref={'input'}
          required={required}
        />
      </div>
    )
  }
}

class AddressFields extends React.Component {
  update_address = (updated_object) => {
    this.props.update_address(updated_object);
  }

  render() {
    const {
      name, phone_number, address_1, address_2, city, state, zip_code, country,
      disabled, state_obj,
    } = this.props;

    return (
      <div className="row">
        <AddressField
          autoComplete="company-name"
          label="Name"
          max_length={45}
          value={name}
          is_big={true}
          disabled={disabled}
          onChange={(e) => this.update_address({name: e.target.value.replace('|','')})}
          required={true}
        />

        <AddressField
          autoComplete="phone-number"
          label="Phone Number"
          max_length={30}
          is_big={true}
          value={phone_number}
          disabled={disabled}
          onChange={(e) => this.update_address({phone_number: e.target.value.replace('|','')}) }
          required={true}
        />

        <AddressField
          ref='address_1'
          autoComplete="address-line1"
          max_length={45}
          label="Address 1"
          is_big={true}
          value={address_1}
          disabled={disabled}
          onChange={(e) => this.update_address({address_1: e.target.value.replace('|','')}) }
          // TODO: figure out how to make enter not trigger a submit of the entire form
          required={true}
        />

        <AddressField
          label="Address 2"
          autoComplete="address-line2"
          value={address_2}
          disabled={disabled}
          max_length={45}
          is_big={true}
          onChange={(e) => this.update_address({address_2: e.target.value.replace('|','')})}
          required={false}
        />

        <Select
          label="Country"
          list={countries}
          value={countries.filter(option => option.value === country)}
          onChange={e => this.update_address({ country: e.value.replace('|','')})}
          required
        />

        <AddressField
          label="City"
          autoComplete="city"
          max_length={25}
          value={city}
          disabled={disabled}
          onChange={(e) => this.update_address({city: e.target.value.replace('|','')})}
          required={true}
        />

        {country.toUpperCase() === 'CA' || country.toUpperCase() === 'US' ? (
          <Select
            label="State"
            onChange={(e) => {
              const state_obj = country.toUpperCase() === 'CA' ? canada_states.find(state => {return state.value == e.value}) :
                us_states.find(state => {return state.value == e.value});
              this.update_address({state: e.value, state_obj: state_obj});
            }}
            list={country.toUpperCase() === 'CA' || country.toUpperCase() == 'CAN' || country.toUpperCase() == 'CANADA' ? canada_states : us_states }
            required
            value={state_obj}
          />
        ) : (
          <AddressField
            label="State"
            autoComplete="state"
            max_length={25}
            value={state}
            disabled={disabled}
            onChange={(e) => this.update_address({state: e.target.value.replace('|','')})}
            required={true}
          />
        )}

        <AddressField
          label="Zip Code"
          autoComplete="zip_code"
          max_length={10}
          value={zip_code}
          disabled={disabled}
          onChange={(e) => this.update_address({zip_code: e.target.value.replace('|','')})}
          required={true}
        />

        {/*<AddressField
          label="Country"
          autoComplete="country"
          max_length={10}
          value={country}
          disabled={disabled}
          onChange={(e) => this.update_address({country: e.target.value.replace('|','')})}
          required={true}
        />*/}
      </div>
    )
  }
}

AddressFields.propTypes = {
  update_address: PropTypes.func.isRequired,

  name: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  address_1: PropTypes.string.isRequired,
  address_2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip_code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}

export {AddressFields, EMPTY_SHIPPING_ADDRESS, countries, us_states, canada_states};
