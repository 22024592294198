import React from 'react';

import CustomPage from '../components/CustomPage';

class COAddendum extends React.Component {
  render() {
    return <CustomPage page={'co-addendum'}/>;
  }
}

export default COAddendum;