import React from 'react';
import reactUpdate from 'react-addons-update';
import BaseSelect from 'react-select';
import {Link}  from 'react-router-dom';

import UserStore from './UserStore';
import FixRequiredSelect from './FixRequiredSelect';
import {AddressFields, EMPTY_SHIPPING_ADDRESS, countries, us_states, canada_states} from '../components/AddressFields';

import auth_dao from './auth_dao';
import dao from '../dao';

const company_types = [
  { value: 'Furniture Store', label: 'Furniture Store' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Ecommerce', label: 'Ecommerce' },
  { value: 'Home Builder', label: 'Home Builder' },
  { value: 'Home Staging', label: 'Home Staging' },
  { value: 'International', label: 'International' },
  { value: 'Specialty Store', label: 'Specialty Store' },
  { value: 'Designer/Design Firm', label: 'Designer/Design Firm' },
  { value: 'Other', label: 'Other' }
];

const product_types = [
  { value: 'Furniture', label: 'Furniture' },
  { value: 'Soft Goods/Textiles', label: 'Soft Goods/Textiles' },
  { value: 'Rugs', label: 'Rugs' },
  { value: 'Accessories', label: 'Accessories' },
  { value: 'Lighting', label: 'Lighting' },
  { value: 'Resort', label: 'Resort' }
];

const heard_about = [
  { value: 'Las Vegas Tradeshow', label: 'Las Vegas Tradeshow' },
  { value: 'High Point Tradeshow', label: 'High Point Tradeshow' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Friend/Business', label: 'Friend/Business' },
  { value: 'Google Search', label: 'Google Search' },
  { value: 'Other', label: 'Other' }
];

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

class SignupPage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      email_confirm: '',
      password: '',
      password_confirm: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      receive_emails: true,

      company_name: '',
      company_title: '',
      company_type: '',
      address: '',
      city: '',
      zip_code: '',
      country: 'US',

      billing_address: EMPTY_SHIPPING_ADDRESS,

      website: '',
      federal_ein: '',
      customer_category: '',
      how_did_you_hear: '',
      approx_yearly_revenue: '',
      selling_plan: '',
      tell_us_more_about_your_business: '',

      error_message: null,
      is_loading: true,
    }
  }

  submitSignup = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true, error_message: null});

    let fileContent = '';
    getBase64(this.fileUpload.files[0], async(result) => {
      fileContent = result;
      this.setState({"sellers_permit" : fileContent, "file_name" : this.fileUpload.files[0].name + '|' + this.fileUpload.files[0].type})
      try {
        const {user} = await auth_dao.trySignup2(this.state);
        if (user) {
          UserStore.set_user(user);
          this.props.history.push('/account');
        }
      } catch (e) {
        const {error_message} = e.responseJSON;
        this.setState({is_loading: false, error_message});
      }
    });
  }

  toggleChange = () => {
    this.setState({
      receive_emails: !this.state.receive_emails,
    });
  }

  componentDidMount = async () => {
    const setup = await dao.getSiteSetup();
    var values = [];
    if (setup.customer_groups) {
      setup.customer_groups.split(';').forEach(element => {
        values.push({value: element, label: element });
      });
    }
    this.setState({is_loading: false, values});
  }

  setOptions = (e, name) => {
    var list = "";
    if (e) {
      e.forEach(element => list += element.value + ';');
    }
    this.setState({[name]: list});
  }

  onLoginChange = () => {
    const login = document.querySelector('input[name=email]');
    const confirm = document.querySelector('input[name=email_confirm]');
    if (confirm.value === login.value) {
      confirm.setCustomValidity('');
    } else {
      confirm.setCustomValidity('Emails do not match');
    }
  }

  onPasswordChange = () => {
    const password = document.querySelector('input[name=password]');
    const confirm = document.querySelector('input[name=password_confirm]');
    if (confirm.value === password.value) {
      confirm.setCustomValidity('');
    } else {
      confirm.setCustomValidity('Passwords do not match');
    }
  }

  render() {
    const {billing_address} = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            {this.state.error_message && <div className="alert alert-danger" role="alert"> {this.state.error_message} </div> }

            <form onSubmit={this.submitSignup} className="mb-5">
              <h4>Account Information</h4>
              <div className="form-row">
                <div className="form-group col-6">
                    <label>Your Email Address<b style={{color: "red"}}> *</b></label>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={e => {
                        this.onLoginChange();
                        this.setState({ email: e.target.value});
                      }}
                      required
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Confirm Email Address<b style={{color: "red"}}> *</b></label>
                    <input
                      className="form-control"
                      name="email_confirm"
                      type="email"
                      value={this.state.email_confirm}
                      onChange={e => {
                        this.setState({ email_confirm: e.target.value});
                        this.onLoginChange();
                      }}
                      required
                    />
                  </div>
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Password<b style={{color: "red"}}> *</b></label>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    value={this.state.password}
                    onChange={e => {
                      this.setState({ password: e.target.value});
                      this.onPasswordChange();
                    }}
                    required
                  />
                </div>
                <div className="form-group col-6">
                  <label>Confirm Password<b style={{color: "red"}}> *</b></label>
                  <input
                    name="password_confirm"
                    type="password"
                    className="form-control"
                    value={this.state.password_confirm}
                    onChange={e => {
                      this.setState({ password_confirm: e.target.value});
                      this.onPasswordChange();
                    }}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>First Name<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={e => this.setState({ first_name: e.target.value})}
                    required
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Last Name<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={e => this.setState({ last_name: e.target.value})}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Phone Number<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="phone_number"
                    type="tel"
                    value={this.state.phone_number}
                    onChange={e => this.setState({ phone_number: e.target.value})}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>
                    <input type="checkbox"
                      name="receive_emails"
                      checked={this.state.receive_emails}
                      onChange={this.toggleChange}
                    /> Sign up for Newsletter
                  </label>
                </div>
              </div>
              <h4>Company Information</h4>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Company Name<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="company_name"
                    value={this.state.company_name}
                    onChange={e => this.setState({ company_name: e.target.value})}
                    required
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>DBA</label>
                  <input
                    className="form-control"
                    name="company_title"
                    value={this.state.company_title}
                    onChange={e => this.setState({ company_title: e.target.value})}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Company Type</label>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={company_types}
                    onChange={e => this.setOptions(e, 'company_type')}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Country<b style={{color: "red"}}> *</b></label>
                  <Select
                    options={countries}
                    value={countries.filter(option => option.value === this.state.country)}
                    onChange={e => this.setState({ country: e.value})}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>Address<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="address"
                    value={this.state.address}
                    onChange={e => this.setState({ address: e.target.value})} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-4">
                  <label>City<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="city"
                    value={this.state.city}
                    onChange={e => this.setState({ city: e.target.value})} />
                </div>
                <div className="form-group col-4">
                  <label>Zip Code<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="zip_code"
                    value={this.state.zip_code}
                    onChange={e => this.setState({ zip_code: e.target.value})} />
                </div>
                {(this.state.country === 'CA' || this.state.country === 'US') ? (
                  <div className="form-group col-4">
                    <label>State<b style={{color: "red"}}> *</b></label>
                    <Select
                      label="State"
                      onChange={e => this.setState({ state: e.value})}
                      options={this.state.country === 'CA' ? canada_states : us_states }
                    />
                  </div>
                ) : (
                  <div className="form-group col-4">
                    <label>State<b style={{color: "red"}}> *</b></label>
                    <input
                      className="form-control"
                      name="state"
                      value={this.state.state}
                      onChange={e => this.setState({ state: e.target.value})} />
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Resale Number<b style={{color: "red"}}> *</b></label>
                  <input
                    name="federal_ein"
                    type="text"
                    className="form-control"
                    value={this.state.federal_ein}
                    onChange={e => this.setState({federal_ein: e.target.value})}
                    required
                  />
                </div>
                {/*<div className="form-group col-4">
                  <label>State<b style={{color: "red"}}> *</b></label>
                  <input
                    className="form-control"
                    name="resale_state"
                    value={this.state.resale_state}
                    onChange={e => this.setState({ resale_state: e.target.value})} />
                </div>*/}
                <div className="form-group col-6">
                  <label>
                    {this.state.state === "CA" ? "Seller's Permit" : "Business License or Seller's Permit"}
                    <b style={{color: "red"}}> *</b>
                  </label>
                  <input className="form-control" type="file" required ref={(ref) => this.fileUpload = ref}></input>
                </div>
              </div>

              <h4>Billing Address</h4>
              <AddressFields
                update_address={(new_obj) => {
                  const new_billing_address = reactUpdate(billing_address, {$merge: new_obj});
                  this.setState({billing_address: new_billing_address});
                }}
                {...billing_address}
              />

              <h4>More Information</h4>

              <div className="form-row">
                <div className="form-group col-12">
                  <label>Website Address</label>
                  <input
                    name="website"
                    type="text"
                    className="form-control"
                    value={this.state.website}
                    onChange={(e) => this.setState({website: e.target.value})}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>What types of products do you sell?<b style={{color: "red"}}> *</b></label>
                  <Select
                    autoFocus
                    closeMenuOnSelect={false}
                    isMulti
                    options={product_types}
                    onChange={e => this.setOptions(e, 'customer_category')}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>How did you hear about Euro Style?<b style={{color: "red"}}> *</b></label>
                  <Select
                    autoFocus
                    closeMenuOnSelect={false}
                    isMulti
                    options={heard_about}
                    onChange={e => this.setOptions(e, 'how_did_you_hear')}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>What is your annual Sales Volume?<b style={{color: "red"}}> *</b></label>
                  <Select
                    closeMenuOnSelect={true}
                    options={[
                      { value: 1, label: "Under $500K" },
                      { value: 2, label: "$1M - $5M" },
                      { value: 3, label: "$5M - $10M" },
                      { value: 4, label: "$10M - $20M" },
                      { value: 5, label: "$20M +" },
                    ]}
                    onChange={e => this.setState({approx_yearly_revenue: e.label})}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>How do you plan on selling Euro Style products?<b style={{color: "red"}}> *</b></label>
                  <textarea
                    className="form-control"
                    value={this.state.selling_plan}
                    onChange={(e) => this.setState({selling_plan: e.target.value})}/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>Additional Information on your Business</label>
                  <textarea
                    name="tell_us_more_about_your_business"
                    className="form-control"
                    value={this.state.tell_us_more_about_your_business}
                    onChange={(e) => this.setState({tell_us_more_about_your_business: e.target.value})}/>
                </div>
              </div>

              <button className="btn btn-success">Sign Up</button>
            </form>

            <div>
              <Link to="/login">I already have an account</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
      cb(reader.result)
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };
}

export default SignupPage2;