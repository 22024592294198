import React from 'react';

const ErrorBox = ({text}) => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div class="alert alert-danger" role="alert">{text}</div>
      </div>
    </div>
  </div>
);

export default ErrorBox;