import queryString from 'query-string';
import React from 'react';
import _ from 'lodash';

import util from '../util';

import ItemGrid from './ItemGrid';
import ItemUtil from './ItemUtil';

const _track_search = (search_query) => {
  if (!search_query) {
    return;
  }

  window.analytics.track('Entered Search Query', {
    search_query,
  });
}
const track_search_debounced = _.debounce(_track_search, 1000);


class ProductSearch extends React.Component {
  constructor(props) {
    super(props);
    const query_string = props.location.search;
    const {page, query: initial_search_term, filter: initial_filter} = queryString.parse(query_string);

    const initial_page = +page || 1;

    this.state = {
      search_term: initial_search_term || '',
      search_results: null,
      items: null,
      page: initial_page,
      filter: initial_filter || '',
    };
  }

  async componentDidMount() {
    this.search_input.focus();

    const items = await ItemUtil.get_all_items();

    this.setState({items});
    this.updateSearchTerm(this.state.search_term, this.state.filter, this.state.page);
  }

  update_url = ({new_filter, new_page = null, new_search_term = null}) => {
    if (new_page) {
      this.setState({page: new_page});
    }
    if (new_search_term != null) {
      this.setState({search_term: new_search_term});
    }

    const {replace} = this.props.history;
    const {search_term, page} = this.state;

    replace({
      pathname: '/search',
      search: `?query=${new_search_term || search_term}&page=${new_page || page}&filter=${encodeURIComponent(new_filter)}`,
    });
  }

  updateSearchTerm = (search_term, filter, page = null) => {
    this.update_url({new_search_term: search_term, new_page: page || 1, new_filter: filter})

    track_search_debounced(search_term);

    const {items} = this.state;
    if (!items) {
      return;
    }

    const search_results = items.filter((item) => util.filter_item_description(item, search_term));
    this.setState({search_results});
  }

  render() {
    const {search_term, search_results, page} = this.state;

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Product <span className="bold">Search</span></h2>
              <p>Search for a product by name, SKU number or description.</p>

              <input
                value={search_term}
                onChange={(e) => this.updateSearchTerm(e.target.value, this.state.filter)}
                placeholder="Search..."
                className="form-control"
                style={{maxWidth: 200}}
                ref={(input) => {
                  this.search_input = input;
                }}
              />
            </div>
          </div>
        </div>

        <ItemGrid
          update_page={(new_page) => {
            this.update_url({new_page})
          }}
          page={page}
          items={search_results}
          non_filter_items={this.state.items}
          allow_filters={true}
          update_filter={(new_filter) => {
            this.update_url({new_filter})
          }}
          filter={this.state.filter}
        />
      </div>
    );
  }
}

export default ProductSearch;
