import React from 'react';

import CustomPage from '../components/CustomPage';

class CCPAAddendum extends React.Component {
  render() {
    return <CustomPage page={'ccpa-addendum'}/>;
  }
}

export default CCPAAddendum;