import React from 'react';

import CustomPage from '../components/CustomPage';

class CSCAct extends React.Component {
  render() {
    return <CustomPage page={'california-supply-chains-act'}/>;
  }
}

export default CSCAct;