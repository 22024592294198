import queryString from 'query-string';
import React from 'react';

import dao from '../dao';
import util from '../util';
import ItemGrid from '../product/ItemGrid';
import ItemConstants from '../dashboard/ItemConstants';
import LoadingSection from '../components/LoadingSection';


class CategoryHome extends React.Component {
  constructor(props) {
    super(props);
    const query_string = props.location.search;
    const {page, query: initial_search_term, filter: initial_filter} = queryString.parse(query_string);

    const initial_page = +page || 1;

    this.state = {
      is_loading: true,
      html: null,
      search_term: initial_search_term || '',
      search_results: null,
      contract_items: null,
      page: initial_page,
      filter: initial_filter || '',
    };
  }

  async componentDidMount() {
    //this.search_input.focus();
    let [contract_items, setup] = await Promise.all([dao.getItems({
      is_contract: true,
      status: ItemConstants.STATUS.ACTIVE,
    }), dao.getSiteSetup()]);
    this.setState({is_loading: false, contract_items, html: setup.contract_products});
    this.updateSearchTerm(this.state.search_term, this.state.filter, this.state.page);
  }

  update_url = ({new_filter, new_page = null, new_search_term = null}) => {
    if (new_page) {
      this.setState({page: new_page});
    }
    if (new_search_term != null) {
      this.setState({search_term: new_search_term});
    }

    const {replace} = this.props.history;
    const {search_term, page} = this.state;

    replace({
      pathname: '/contract-products',
      search: `?query=${new_search_term || search_term}&page=${new_page || page}&filter=${encodeURIComponent(new_filter)}`,
    });
  }

  updateSearchTerm = (search_term, filter, page = null) => {
    this.update_url({new_search_term: search_term, new_page: page || 1, new_filter: filter})

    //track_search_debounced(search_term);

    const {contract_items} = this.state;
    if (!contract_items) {
      return;
    }

    const search_results = contract_items.filter((item) => util.filter_item_description(item, search_term));
    this.setState({search_results});
  }

  render() {
    const {is_loading, html} = this.state;
    const {search_term, search_results, page} = this.state;

    if (is_loading)
      return <LoadingSection/>;

    return (
      <div>
        <div className="container">
          <div className="row">
            {html && (<div className="col-12" dangerouslySetInnerHTML={{__html: html}}></div>)}
          </div>
          <div className="row">
            <div className="col-12">
              <input
                value={search_term}
                onChange={(e) => this.updateSearchTerm(e.target.value, this.state.filter)}
                placeholder="Search..."
                className="form-control"
                style={{maxWidth: 200}}
                ref={(input) => {
                  this.search_input = input;
                }}
              />
            </div>
          </div>
        </div>

        <ItemGrid
          update_page={(new_page) => {
            this.update_url({new_page})
          }}
          page={page}
          items={search_results}
          non_filter_items={this.state.contract_items}
          allow_filters={true}
          update_filter={(new_filter) => {
            this.update_url({new_filter})
          }}
          filter={this.state.filter}
        />
      </div>
    );
  }
}

export default CategoryHome;
