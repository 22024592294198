import React from 'react';
import queryString from 'query-string';

import auth_dao from './auth_dao';
import UserStore from './UserStore';

class NewUserPage extends React.Component {
  constructor(props) {
    const query_string = props.location.search;
    const {email, token} = queryString.parse(query_string);

    super(props);
    this.state = {
      email,
      token,
      first_name: '',
      last_name: '',
      company_title: '',
      phone_number: '',
      receive_emails: true,
      password: '',
      sell_online: false,
      error_message: null,
      is_loading: true,
    }
  }

  toggleChange = () => {
    this.setState({
      receive_emails: !this.state.receive_emails,
    });
  }

  setData = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true, error_message: null});

    const {email, first_name, last_name, company_title, phone_number, receive_emails, password, sell_online, token} = this.state;
    try {
      const user = await auth_dao.setNewUser({email, first_name, last_name, company_title, phone_number, receive_emails, password,
        sell_online, token});
      if (user) {
        this.props.history.push('/');
        UserStore.set_user(user);
      }
    } catch (e) {
      const {error_message} = e.responseJSON;
      this.setState({is_loading: false, error_message});
    }
  }

  render() {
    const {email, first_name, last_name, company_title, phone_number, receive_emails, password, sell_online, error_message} = this.state;

    return (
      <div className="container">
        <div className="col-12 col-sm-8 col-md-6 mx-auto">
          <h3>Sign Up</h3>

          {error_message && <div className="alert alert-danger" role="alert"> {error_message} </div> }

          <form onSubmit={this.setData}>
          <div className="form-group">
              <label>Email<b style={{color: "red"}}> *</b></label>
              <input
                name="givenName"
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <label>First Name<b style={{color: "red"}}> *</b></label>
              <input
                name="givenName"
                type="text"
                className="form-control"
                value={first_name}
                onChange={(e) => this.setState({first_name: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name<b style={{color: "red"}}> *</b></label>
              <input
                name="surname"
                type="text"
                className="form-control"
                value={last_name}
                onChange={(e) => this.setState({last_name: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <label>Company Title</label>
              <input
                name="title"
                type="text"
                className="form-control"
                value={company_title}
                onChange={(e) => this.setState({company_title: e.target.value})}
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                name="phone_number"
                type="tel"
                className="form-control"
                value={phone_number}
                onChange={(e) => this.setState({phone_number: e.target.value})}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                name="password"
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => this.setState({password: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <label>
                <input type="checkbox"
                  name="sell_online"
                  checked={sell_online}
                  onChange={() => this.setState({ sell_online: !this.state.sell_online })}
                /> Do you sell online?
              </label>
            </div>
            <div className="form-group">
              <label>
                <input type="checkbox"
                  name="receive_emails"
                  checked={receive_emails}
                  onChange={this.toggleChange}
                /> Uncheck this box if you do not wish to receive our marketing emails.
              </label>
            </div>
            <button className="btn btn-success">Sign Up</button>
          </form>
        </div>
      </div>
    )
  }
}

export default NewUserPage;
