import PropTypes from 'prop-types'
import React from 'react';
import {Link} from 'react-router-dom';

import CustomerUtil from './CustomerUtil';
import CustomerList from './CustomerList';
import util from '../util';

class CustomerSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search_text: '',
      all_customers: null,
      customers: null,

      is_loading: true,
      error: null,
    };
  }

  componentDidMount = async() => {
    this.search_input.focus();

    const customers = await CustomerUtil.get_customers();

    let all_customers = customers;

    this.setState({customers: all_customers, all_customers, is_loading: false});
    this.update_search_text(this.state.search_text);
  }

  update_search_text = (search_text) => {
    this.setState({search_text});

    const {all_customers} = this.state;
    if (!all_customers) {
      return;
    }

    const customers = all_customers.filter(
      (candidate) => util.filter_object_by_fields(candidate, search_text,
        ['id', 'name', 'email', 'website', 'city', 'state', 'zip_code']));
    this.setState({customers});
  }

  render() {
    const {show_new_customer_link, customer_list_props} = this.props;
    const {customers, is_loading, error, search_text} = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div style={{maxWidth: 300}} className="col-12 col-sm-8">
              <input
                className="form-control"
                placeholder="Search for a customer"
                type="text"
                ref={(input) => {
                  this.search_input = input;
                }}
                value={search_text}
                onChange={(e) => this.update_search_text(e.target.value)}
              />
            </div>

            {show_new_customer_link && (
              <div className="col-12 col-sm-4">
                <Link to="/internal/customers/new">New Customer</Link>
              </div>
            )}

          </div>
        </div>

        <div className="col-12">
          {error && (
            <div className="alert alert-danger">{error}</div>
          )}

          {is_loading && (
            <div className="alert alert-warning">Loading...</div>
          )}

          {!is_loading && (
            <CustomerList
              customers={customers}
              {...customer_list_props}
            />
          )}
        </div>
      </div>
    );
  }
}

CustomerSearch.propTypes = {
  show_new_customer_link: PropTypes.bool.isRequired,
}

const CustomerSearchPage = (props) => (
  <div className="container">
    <div className='row'>
      <div className='col-12 mx-auto'>
        <h1><span className="bold">Customers</span></h1>

        <CustomerSearch
          show_new_customer_link={props.editable}
          customer_list_props={{
            render_action_cell: (customer) => (
              <td>
                <Link
                  to="/"
                  onClick={() => {
                    // TODO: figure out if I want to wait to navigate to the next page
                    CustomerUtil.set_current_customer(customer)
                  }}
                >
                  Set as current
                </Link>
              </td>
            )
          }}
        />
      </div>
    </div>
  </div>
)

export {CustomerSearchPage, CustomerSearch};
