import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

class LoggedInDropdown extends React.Component {
  render() {
    const {user} = this.props;
    if (!user) {
      return [
        <li className="nav-item" key="login">
          <Link className="nav-link" to="/login">
            Login
          </Link>
        </li>,
        <Link to="/signup" className="btn btn-success navbar-btn" key="open-account">
          Open Account
        </Link>
      ]
    }

    if (user.user_type === 'Employee' && user.rep_code) {
      return (
        <div className="nav-item dropdown">
          <button type="button"
            className="btn btn-link shadow-none dropdown-toggle text-light"
            id="publicHeader-account-dropdown'"
            data-toggle="dropdown"
          >
            Account
          </button>

          <div className="dropdown-menu" id='publicHeader-account-dropdown'>
            <Link className="dropdown-item" to='/customers'>Customers</Link>
            <Link className="dropdown-item" to='/presentations'>Presentations</Link>
            <Link className="dropdown-item" to='/set-price-level'>Price Level</Link>

            <div className="dropdown-divider"/>

            <Link className="dropdown-item" to='/account'>Profile</Link>
            <Link className="dropdown-item" to='/requests/claim'>Claim Form</Link>
            <a className="dropdown-item" href='/auth/logout'>Log Out</a>
          </div>
        </div>
      )
    }

    if (user.user_type === 'Employee') {
      return (
        <div className="nav-item dropdown">
          <button type="button"
            className="btn btn-link shadow-none dropdown-toggle text-light"
            id="publicHeader-account-dropdown'"
            data-toggle="dropdown"
          >
            Account
          </button>

          <div className="dropdown-menu" id='publicHeader-account-dropdown'>
            <Link className="dropdown-item" to='/internal/reports'>Internal</Link>
            <Link className="dropdown-item" to='/scanner'>Scanner</Link>
            <Link className="dropdown-item" to='/internal/customers'>Customers</Link>
            <Link className="dropdown-item" to='/internal/users'>Users</Link>
            <Link className="dropdown-item" to='/internal/presentations'>Presentations</Link>
            <Link className="dropdown-item" to='/internal/set-price-level'>Price Level</Link>
            <Link className="dropdown-item" to='/last_orders'>Last Orders</Link>
            <Link className="dropdown-item" to='/orders_to_enter'>Orders To Enter</Link>

            <div className="dropdown-divider"/>

            <Link className="dropdown-item" to='/account'>Profile</Link>
            {this.props.customer && (<Link className="dropdown-item" to='/customer'>Customer Info</Link>)}
            <Link className="dropdown-item" to='/requests/claim'>Claim Form</Link>
            <a className="dropdown-item" href='/auth/logout'>Log Out</a>
          </div>
        </div>
      )
    }

    return (
      <div className="nav-item dropdown">
        <button type="button"
          className="btn btn-link shadow-none dropdown-toggle text-light"
          id="publicHeader-account-dropdown'"
          data-toggle="dropdown"
        >
          Account
        </button>

        <div className="dropdown-menu" id='publicHeader-account-dropdown'>
          <Link className="dropdown-item" to='/account'>Profile</Link>
          {this.props.customer && (<Link className="dropdown-item" to='/customer'>Customer Info</Link>)}
          <Link className="dropdown-item" to='/requests/claim'>Claim Form</Link>
          <a className="dropdown-item" href='/auth/logout'>Log Out</a>
        </div>
      </div>
    )

  }
}

LoggedInDropdown.propTypes = {
  user: PropTypes.object,
}

export default LoggedInDropdown;