import React from 'react';

import CustomPage from '../components/CustomPage';

class CTAddendum extends React.Component {
  render() {
    return <CustomPage page={'ct-addendum'}/>;
  }
}

export default CTAddendum;