import React from 'react';

import CustomPage from '../components/CustomPage';

class PISOORights extends React.Component {
  render() {
    return <CustomPage page={'personal-information-sales-opt-out-and-opt-in-rights'}/>;
  }
}

export default PISOORights;