import React from 'react';
import BaseSelect from 'react-select';

import ItemUtil from '../product/ItemUtil';
import dao from '../dao';
import LoadingSection from '../components/LoadingSection';
import FixRequiredSelect from '../auth/FixRequiredSelect';
import UserStore from '../auth/UserStore';
import { us_states } from '../components/AddressFields';
import {FormInput, get_initial_state_from_field_definitions} from './FormInput';
import {EMPTY_SHIPPING_ADDRESS} from '../components/AddressFields';

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const SWATCH_REQUEST_FIELDS = [
  {
    key: 'company_name',
    display: 'Company Name',
    type: 'text',
    required: true,
  },
  {
    key: 'email',
    display: 'Email',
    type: 'email',
    required: true,
  },
  {
    key: 'company_website',
    display: 'Company Website',
    type: 'text',
    required: true,
  },
  {
    key: 'phone',
    display: 'Phone',
    type: 'tel',
  },
  {
    key: 'how_did_you_hear_about_us',
    display: 'How did you hear about us?',
    type: 'textarea',
    required: true,
  },
  /*{
    key: 'request_details',
    display: 'Request Details',
    infoNode: (
      <p>
        Please include item name, item number (SKU), colors, and quantities.
      </p>
    ),
    required: true,
    type: 'textarea',
  },*/
  {
    key: 'shipping_address',
    display: 'Shipping Address',
    type: 'address',
    initial_value: EMPTY_SHIPPING_ADDRESS,
    large: true,
    id: 'ship',
  },
];

class ItemSwatchRequestForm extends React.Component {
  constructor(props) {
    super(props);
    const user_state = this.getUserState();
    this.state = {
      form: get_initial_state_from_field_definitions(SWATCH_REQUEST_FIELDS),
      item: null,
      variation_items: null,
      ...user_state,
    }
  }

  componentDidMount = async () => {
    UserStore.on('change', this.setUserState);
    const {sku} = this.props;
    const item = await ItemUtil.get_item(sku);
    if (item == null) {
      return;
    }

    var variation_items = null;
    if (item.variation_color_sku) {
      variation_items = await dao.getItemVariations(item.variation_color_sku);
    }
    this.setState({item, variation_items});
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserState);
  }

  getUserState = () => {
    return {
      customer: UserStore.get_customer(),
      user: UserStore.get_user(),
    };
  }

  setUserState = () => {
    const {user, customer} = this.getUserState();
    const newForm = { ...this.state.form};
    const shippingAddress = { ...newForm.shipping_address };

    if (user) {
      newForm.company_name = user.company_name;
      newForm.email = user.email;
      newForm.phone = user.phone_number;
    }

    if (customer) {
      newForm.company_website = customer.website || '';
      shippingAddress.address_1 = customer.address_1 || '';
      shippingAddress.address_2 = customer.address_2 || '';
      shippingAddress.city = customer.city || '';
      shippingAddress.country = "US";
      shippingAddress.name = customer.name || '';
      shippingAddress.phone_number = customer.phone_number || '';
      shippingAddress.state = customer.state || '';
      shippingAddress.state_obj =  us_states.find(state => {return state.value == customer.state});
      shippingAddress.zip_code = customer.zip_code || '';
      newForm.shipping_address = shippingAddress;
      //document.querySelectorAll("#state_ship input")[0].value = { label: customer.state, value: customer.state};
    }
    this.setState({form : newForm, user, customer});
  }

  requestCatalog = async (e) => {
    e.preventDefault();

    const {shipping_address} = this.state.form;
    const request_data = Object.assign({...shipping_address}, this.state.form);

    await dao.createSwatchRequest(request_data);
    window.location = '/requests/success';
  }

  setColors = (e) => {
    var list = "";
    if (e) {
      e.forEach(element => list += element.value + ';');
    }
    this.setState({form : {...this.state.form, request_details: list}});
  }

  render() {
    const { item, variation_items, user, customer } = this.state;
    if (!item) {
      return <LoadingSection/>;
    }

    if (!variation_items) {
      return (
        <div className="container">
          <p>No colors found</p>
        </div>
      )
    }

    const colors = variation_items.map(item => { return {value: item.sku, label: item.variation_color_value}});
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto">
            <h2>Request A <span className="bold">Swatch</span></h2>

            <p>
              Are you curious to see what our materials look like up close? Or are you interested in
              modifying one of our products?
            </p>

            <p>
              Fill out the following forms to request a swatch and we will be in touch shortly!
            </p>

            <h3>{item.product_name}</h3>
            <form onSubmit={this.requestCatalog}>
              <div className="row">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <label className="font-weight-bold">Selected Swatches<b style={{color: 'red'}}> *</b></label>
                    <Select
                      defaultValue={colors.filter(option => option.value === this.props.sku)}
                      isMulti
                      name="colors"
                      options={colors}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.setColors}
                      required
                    />
                  </div>
                </div>

                {SWATCH_REQUEST_FIELDS.map((request_field) => (
                  <FormInput
                    updateValue={(new_value) => {
                      this.setState({form : {...this.state.form, [request_field.key]: new_value}});
                      //console.log('Q', JSON.stringify(request_field), new_value);
                    }}
                    value={this.state.form[request_field.key]}
                    fieldKey={request_field.key}
                    {...request_field}
                  />
                ))}

              </div>

              <button className="btn btn-success" type="submit">
                Submit Request
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ItemSwatchRequestForm;