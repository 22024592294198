import React from 'react';

import CustomPage from '../components/CustomPage';

class PaymentTypesPage extends React.Component {
  render() {
    return <CustomPage page={'payment-types'}/>;
  }
}

export default PaymentTypesPage;