import React from 'react';
import {Link} from 'react-router-dom';

import DescriptionList from '../components/DescriptionList';
import ComponentsDimensions from './ComponentsDimensions';
import ItemUtil from './ItemUtil';

const ProductImage = ({item}) => (
  <div className="col-12 col-sm-6 col-md-4">
    {item.item_image && item.item_image !== '' && (
      <div style={{height: 300}}>
        <div id="dimDiv"></div>
        <button id="dimButton" onClick={() => expandImage(false)}>
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <g fill="#000" fill-rule="nonzero" stroke="#000">
                <path d="M18.6753571 2.08152486l-.6487597-.67476795c-.3578377-.37260221-.9393896-.37260221-1.2979286 0L9.98071429 8.33578453 3.21534416 1.30137569c-.35848052-.37254144-.93974026-.37254144-1.29792858 0l-.64875974.6746464c-.3581883.37272377-.3581883.97644752 0 1.34923205l8.055 8.37634806c.35818832.3729061.93898052.3729061 1.29757793 0l8.05412333-8.27102761c.359065-.37254144.359065-.97650829 0-1.34904973z"></path>
                <path d="M18.6753571 17.91847514l-.6487597.67476795c-.3578377.37260221-.9393896.37260221-1.2979286 0l-6.74795451-6.92902762-6.76537013 7.03440884c-.35848052.37254144-.93974026.37254144-1.29792858 0l-.64875974-.6746464c-.3581883-.37272377-.3581883-.97644752 0-1.34923205l8.055-8.37634806c.35818832-.3729061.93898052-.3729061 1.29757793 0l8.05412333 8.27102761c.359065.37254144.359065.97650829 0 1.34904973z"></path>
              </g>
            </svg>
        </button>
        <img id="dimImage" src={"https://s3-us-west-2.amazonaws.com/eurostyle-item-photos/" + item.item_image}
          alt="no image"
          style={{maxHeight: '100%', objectFit: 'contain', cursor: 'zoom-in'}}
          onClick={() => expandImage(true)}>
        </img>
      </div>
    )}
  </div>
);

function expandImage(isExpand) {
  if (isExpand) {
    document.getElementById("dimImage").classList.add("big-image");
    document.getElementById("dimImage").style.cursor = 'default';
    document.getElementById("dimDiv").style.display = 'block';
    document.getElementById("dimButton").style.display = 'block';
  } else {
    document.getElementById("dimImage").classList.remove("big-image");
    document.getElementById("dimImage").style.cursor = 'zoom-in';
    document.getElementById("dimDiv").style.display = 'none';
    document.getElementById("dimButton").style.display = 'none';
  }
}

const PRODUCT_DIMENSION_ATTRIBUTE_TO_LABEL = [
  {
    render_func: ({product_height, product_height_min}) =>
      ItemUtil.num_to_display(product_height, product_height_min, 1, '"'),
    label: 'Height',
  },
  {
    render_func: ({seat_height_max, seat_height_min}) =>
      ItemUtil.num_to_display(seat_height_max, seat_height_min, 1, '"'),
    label: 'Seat Height',
  },
  {
    render_func: ({arm_height_from_floor_min, arm_height_from_floor_max}) =>
      ItemUtil.num_to_display(arm_height_from_floor_min, arm_height_from_floor_max, 1, '"'),
    label: 'Arm Height',
  },
  {
    render_func: ({product_depth, product_depth_min}) =>
      ItemUtil.num_to_display(product_depth, product_depth_min, 1, '"'),
    label: 'Depth / Length',
  },
  {
    render_func: ({product_width, product_width_min}) =>
      ItemUtil.num_to_display(product_width, product_width_min, 1, '"'),
    label: 'Width',
  },
  {
    attribute: 'additional_dimensions',
    label: 'Additional Dimensions',
  },
]

const ProductDimensions = ({item}) => (
  <div className="col-12 col-sm-6 col-md-4">
    <h4 className="text-center">Product Dimensions</h4>

    <DescriptionList
      style={{fontSize: '0.85rem'}}
      col_className='col-6'
      render_empty_cells={false}
      description_schemas={PRODUCT_DIMENSION_ATTRIBUTE_TO_LABEL}
      data={item}
    />

    {item.item_type === 'KIT' && <ComponentsDimensions sku={item.sku} />}
  </div>
);

const SHIPPING_INFO_ATTRIBUTE_TO_LABEL = [
  {
    attribute: 'assembly_type',
    label: 'Assembly',
  },
  {
    attribute: 'ship_method',
    label: 'Ships via',
  },
  {
    label: 'Carton Dims',
    render_func: (item) => {
      const {carton_length, carton_width, carton_height} = item;
      return ItemUtil.to_dims_string(carton_length, carton_height, carton_width);
    },
  },
  {
    label: 'Carton Weight',
    render_func: (item) => <span>{ItemUtil.round_dim(item.carton_weight)} lbs.</span>,
  },
  {
    label: 'Carton Volume',
    render_func: (item) => <span>{ItemUtil.round_dim(item.carton_volume)} ft<sup>3</sup></span>,
  },
  // {
  //   label: 'Carton Density',
  //   render_func: (item) =>
  //     <span>{round_dim(item.carton_weight / item.carton_volume)} lbs/ft<sup>3</sup></span>,
  // },
  // {
  //   label: 'Pallet Dims',
  //   render_func: (item) => {
  //     if (!item.pallet_size) return null;
  //     const {pallet_length, pallet_width, pallet_height} = item;
  //     return to_dims_string(pallet_length, pallet_height, pallet_width);
  //   },
  // },
  // {
  //   label: 'Pallet Weight',
  //   render_func: (item) => {
  //     if (!item.pallet_size) return null;
  //     return <span>{round_dim(item.pallet_weight)} lbs.</span>;
  //   },
  // },
  // {
  //   attribute: 'moq',
  //   label: 'MOQ (Special Orders)',
  // },
  {
    attribute: 'country_of_origin',
    label: 'Country of Origin',
  },
  {
    label: 'Consolidation Code',
    render_func: (item) => <Link to={`/factory/${item.vendor_code}`}>{item.vendor_code}</Link>,
  }
];

const ShippingInfo = ({item}) => (
  <div className="col-12 col-sm-6 col-md-4">
    <h4 className="text-center">Shipping Info</h4>

    <DescriptionList
      style={{fontSize: '0.85rem'}}
      col_className='col-6'
      render_empty_cells={false}
      description_schemas={SHIPPING_INFO_ATTRIBUTE_TO_LABEL}
      data={item}
    />
  </div>
)

const ProductStatsSection = ({item}) => (
  <div className="row">
    <ProductImage item={item}/>
    <ProductDimensions item={item}/>
    <ShippingInfo item={item}/>
  </div>
)

export default ProductStatsSection;
