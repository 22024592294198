import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';

import UserStore from '../auth/UserStore';
import OrderDAO from './OrderDAO';
import {OrderShippingInfoWrapper, OrderShippingAddress} from './OrderShipping';
import {
  OrderItemsTable, ITEM_CARTON_COLUMN, ITEM_VOLUME_COLUMN, ITEM_WEIGHT_COLUMN
} from './OrderItemsTable';
import ItemStore from '../product/ItemStore';
import util from '../util';
import DescriptionList from '../components/DescriptionList';
import ErrorBox from '../components/ErrorBox';
import LoadingSection from '../components/LoadingSection';
import Price from '../components/Price';


class UpdateableField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loading: false,
      is_editing: props.start_in_edit_mode,

      new_value: props.value || '',
    }
  }

  update_value = async(e) => {
    e.preventDefault();
    const {attribute_name, update_func} = this.props;
    const {new_value} = this.state;
    const data = {[attribute_name]: new_value};

    await update_func(data);

    // TODO: fire off an update callback instead of forcing a reload
    window.location.reload();
  }

  render() {
    const {value, style, route_to_link_to} = this.props;
    const {is_loading, is_editing, new_value} = this.state;

    if (is_loading) {
      return <div className="alert alert-warning">Loading...</div>;
    }

    if (!is_editing) {
      return (
        <div style={style}>
          {route_to_link_to ? (
              <Link to={route_to_link_to}>
                {value}
              </Link>
            ) : (
              value
            )}
          {' '}
          <FontAwesome
            name="pencil"
            onClick={() => this.setState({is_editing: true})}
          />
        </div>
      );
    }

    return (
      <div style={style}>
        <form onSubmit={this.update_value}>
          <input
            type="text"
            className="w-75 mr-2"
            value={new_value}
            onChange={(e) => this.setState({new_value: e.target.value})}
          />

          <FontAwesome
            name="check"
            onClick={this.update_value}
          />
        </form>
      </div>
    )
  }
}

UpdateableField.propTypes = {
  update_func: PropTypes.func.isRequired, // Called with object {[attribute_name]: new_value}
  value: PropTypes.any.isRequired,
  attribute_name: PropTypes.string.isRequired,
  start_in_edit_mode: PropTypes.bool.isRequired,
  route_to_link_to: PropTypes.string,
}

UpdateableField.defaultProps = {
  start_in_edit_mode: false,
}

const CancelOrderButton = ({order_id}) => (
  <span
    className="btn btn-danger"
    onClick={() => {
      if (window.confirm('Do you want to cancel this order? - Click OK to cancel the order.')) {
        OrderDAO.update_order(order_id, {status: 'Canceled'});

        window.location.reload();
      }
    }}
  >
    Cancel Order
  </span>
);

CancelOrderButton.propTypes = {
  order_id: PropTypes.number.isRequired,
}

const OrderShippingSection = ({order_id}) => (
  <OrderShippingInfoWrapper
    render={({order, shipping_address, update_shipping_address_on_server}) => {
      return (
        <div>
          <h4 className="mb-2">Shipping</h4>

          <OrderShippingAddress
            shipping_address={shipping_address}
            update_shipping_address={update_shipping_address_on_server}
            show_warning={false}
          />
        </div>
      )
    }}
    order_id={order_id}
  />
)

OrderShippingSection.propTypes = {
  order_id: PropTypes.number.isRequired,
}

class OrderInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({
      order: null,
      error: null,
    }, this.getUserState());
  }

  get_order_id = () => this.props.match.params.order_id;

  async componentDidMount() {
    UserStore.on('change', this.setUserState);

    const order_id = this.get_order_id();

    try {
      const {order, order_items, items, customer} = await OrderDAO.get_order(order_id);
      ItemStore.add_items(items);

      this.setState({order, order_items, items, customer});
    } catch(error) {
      console.log(error);
      if (error.status && error.status === 401)
        this.setState({error: error.statusText});
    }
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserState);
  }

  getUserState = () => {
    return {user: UserStore.get_user()};
  }

  setUserState = () => {
    this.setState(this.getUserState());
  }

  send_order_to_nav_queue = async() => {
    const order_id = this.get_order_id();
    await OrderDAO.send_to_nav_queue(order_id);
    window.location.reload()
  }

  render() {
    const {order, order_items, customer, user, error} = this.state;

    const user_is_employee = user && user.user_type === 'Employee';

    if (error)
      return <ErrorBox text={error}/>;

    if (!order)
      return <LoadingSection/>;

    const sku_to_order_items = {};
    order_items.forEach((order_item) => {
      const {item_sku} = order_item;
      const existing_order_items = sku_to_order_items[item_sku];

      if (!existing_order_items) {
        sku_to_order_items[item_sku] = [order_item];
      } else {
        sku_to_order_items[item_sku].push(order_item);
      }
    });

    const sku_to_quantity = _.mapValues(sku_to_order_items, (order_items) => order_items.length);
    const address_is_empty = (order.shipping_address == null) || (order.shipping_address.address_1 === "") ||
      (order.shipping_address.name === "") || (order.shipping_address.zip_code === "");

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="mb-4">
              Order {order.id} {' '}
              &mdash; {' '}
              {user_is_employee ? (
                  <Link to={`/internal/customers/${customer.id}`}>{customer.name}</Link>
                ) : (
                  customer.name
                )}
            </h3>

            <DescriptionList
              description_schemas={_.filter([
                {label: 'Customer PO', attribute: 'customer_purchase_order_id'},
                {
                  label: 'Sales Order',
                  render_func: ({id, sales_order_id}) =>
                    <UpdateableField
                      route_to_link_to={sales_order_id ? `/sales-orders/${sales_order_id}` : null}
                      update_func={OrderDAO.update_order.bind(null, id)}
                      value={sales_order_id || ''}
                      attribute_name='sales_order_id'
                    />
                },
                {label: 'Written By', attribute: 'written_by'},
                {label: 'Status', attribute: 'status'},
                {
                  label: 'Payment Type',
                  attribute: 'payment_type'
                },
                (user_is_employee ? {label: 'Card ID', attribute: 'credit_card_stripe_id'} : null),
                {label: 'Submitted', render_func: ({created_at}) => util.date_format_iso_to_day(created_at)},
                (user_is_employee ? {label: 'Price Level', attribute: 'price_level'} : null),
                {label: 'Notes', attribute: 'notes_user'},
              ])}
              data={Object.assign({
                price_level: util.PRICE_LEVEL_TO_PRICE_GROUP[order.price_level],
                written_by: order.user_who_ordered_email,
              }, order)}
              render_empty_cells={true}
            />

            {user_is_employee && order.status !== 'Canceled' && !order.sales_order_id && (
              <div className="row mb-4">
                <div className="col-12 col-md-6 mx-auto d-flex justify-content-between">
                  {!user.rep_code && (order.sent_to_nav_queue_at ? (
                      <div>
                        Sent to NAV queue
                        on {moment.utc(order.sent_to_nav_queue_at).local().format('dddd, HH:mm')}.
                        {' '} Waiting to be processed.
                        <button
                          className="btn btn-success"
                          onClick={this.send_order_to_nav_queue}
                          disabled={customer.id > 100000}
                        >
                          Resend to NAV
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="btn btn-success"
                          onClick={this.send_order_to_nav_queue}
                          disabled={customer.id > 100000 || address_is_empty}
                        >
                          Send to NAV
                        </button>
                        {(customer.id > 100000) && (
                          <div className="text-danger">
                            You must enter customer into NAV before sending order to NAV.
                          </div>
                        )}
                        {address_is_empty && (
                          <div className="text-danger">
                            You must complete shipping information before sending the order to NAV.
                          </div>
                        )}
                      </div>
                    ))}

                  {!order.sent_to_nav_queue_at && (
                    <div>
                      <CancelOrderButton order_id={order.id}/>
                    </div>
                  )}
                </div>
              </div>
            )}

            {order_items && (
              <OrderItemsTable
                skus={_.map(sku_to_quantity, (quantity, sku) => sku)}
                extra_columns={[
                  {
                    key: 'quantity',
                    header_text: 'Quantity',
                    right_align: true,
                    to_value: (item, row_context) => row_context.quantity,
                    render: (item, value) => value,
                  },
                  {
                    key: 'unit_cost',
                    header_text: 'Unit Cost',
                    right_align: true,
                    to_value: (item, row_context) => sku_to_order_items[item.sku][0].cost,
                    render: (item, value) => <Price price={value}/>,
                  },
                  {
                    key: 'cost',
                    header_text: 'Cost',
                    right_align: true,
                    show_subtotal: true,
                    to_value: (item, row_context) => (
                      sku_to_order_items[item.sku][0].cost * row_context.quantity
                    ),
                    render: (item, value) => <Price price={value}/>,
                  },
                  ITEM_VOLUME_COLUMN,
                  ITEM_WEIGHT_COLUMN,
                  ITEM_CARTON_COLUMN,
                ]}
                get_row_context={(item) => ({quantity: sku_to_quantity[item.sku]})}/>
            )}

            <hr />

            <OrderShippingSection order_id={order.id}/>
          </div>
        </div>
      </div>
    );
  }
}

export {OrderInfoPage, UpdateableField};
