import * as net from './net';

const dao = {};

dao.getImages = ({get_untagged = null}) => net.get({url: '/api/images', data: {get_untagged}});

dao.getItems = ({
  category = null,
  status = null,
  skus = null,
  is_shown_on_public_website = true,
  is_ok_for_outdoor = null,
  is_show_special = false,
  is_new = false,
  is_contract = false,
  show_components = false,
  product_group = null,
  vendor_code = null,
  promotion = null,
  free_products = null,
  discount_code = null,
}) =>
  net.get({
    url: '/api/items',
    data: {
      skus, category, status, is_contract, is_new, is_shown_on_public_website, show_components, vendor_code,
      is_show_special, is_ok_for_outdoor, product_group, promotion, free_products, discount_code
    }
  });

dao.getItem = (sku) => net.get({url: `/api/items/${sku}`});

dao.getImagesForItem = (sku) => net.get({url: `/api/items/${sku}/images`});

dao.getAssemblyInstructionsForItem = (sku) => net.get({url: `/api/items/${sku}/assembly-instructions`});

dao.getItemKits = (sku) => net.get({url: `/api/items/${sku}/kits`});

dao.getItemComponents = (sku) => net.get({url: `/api/items/${sku}/components`});

dao.getItemVariations = (variation_color_sku) =>
  net.get({url: `/api/items/variations/${variation_color_sku}`});

dao.get_best_customers = (item_sku) =>
  net.get({url: `/api/items/${item_sku}/best-customers`});

dao.getCollections = () => net.get({url: '/api/collections'});

dao.getCollection = (collection_name_or_id) => net.get({url: `/api/collections/${collection_name_or_id}`});

dao.getCollectionItems = (collection_id) => net.get({url: `/api/collections/${collection_id}/items`});

dao.createCatalogRequest = (data) => net.post({url: '/api/catalog-requests', data});

dao.createSwatchRequest = (data) => net.post({url: '/api/swatch-requests', data});

dao.createUserRequest = (data) => net.post({url: '/api/user-requests', data});

dao.createAppointment = (data) => net.post({url: '/api/appointments', data});

dao.createNewAccountApplication = (data) => net.post({url: '/api/new-account-application', data});

dao.requestAccountApproval = (data) => net.post({url: '/api/requests/account-approval', data});

dao.requestClaim = (files, data) => net.postFiles({url: '/api/requests/claim', files, data});

dao.createProductGrid = (data) => net.post({url: '/api/reports/export-product-grid', data});

dao.generateProductTags = (data) => net.post({url: '/api/reports/generate-product-tags', data});

dao.generate_product_brochure = (data) => net.post({url: '/api/reports/generate-product-brochure', data});

dao.getNewAccountApplication = () => net.get({url: `/api/users/current-user/new-account-application`});

dao.getSiteSetup = () => net.get({url: '/auth/site-setup'});

dao.get_categories = () => net.get({url: '/api/categories'});

dao.getDownloadFiles = () => net.get({url: '/api/download_files'});

dao.deleteDownloadFile = (key) => net.delete_({url: '/api/download_files/' + key});

dao.getItemSpecification = (sku) => net.get({url: `/api/items/${sku}/item-spec`});

export default dao;
