import React from 'react';

import LoadingSection from './LoadingSection';

class CustomPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      html: null,
    }
  }

  componentDidMount = async () => {
    fetch('/api/pages/' + this.props.page)
    .then((res) => res.json())
    .then(
      (result) => {
        this.setState({
          html: result.html
        });
      },
      (error) => {
        console.log(error)
      }
    );
  }

  render() {
    const {html} = this.state;

    return (
      <>
        {html === null && <LoadingSection />}
        {html && <div className='container' dangerouslySetInnerHTML={{__html: html}}/>}
      </>
    )
  }
}

export default CustomPage;