import * as net from '../net';

const auth_dao = {};

auth_dao.tryLogin = (data) => net.post({url: '/auth/login', data});

auth_dao.trySignup = (data) => net.post({url: '/auth/signup', data});

auth_dao.trySignup2 = (data) => net.post({url: '/auth/signup2', data});

auth_dao.requestNewPassword = (data) => net.post({url: '/auth/request-new-password', data});

auth_dao.setPassword = (data) => net.post({url: '/auth/set-password', data});

auth_dao.setNewUser = (data) => net.post({url: '/auth/set-new-user', data});

export default auth_dao;
