import React from 'react';

import CustomPage from '../components/CustomPage'


function AboutPage() {
  return <CustomPage page="history" />;
}

export default AboutPage;
