import classNames from 'classnames';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Link} from 'react-router-dom';

import UserStore from '../auth/UserStore';

import PublicHeader from '../nav/PublicHeader';

import UploadImagesPage from '../dashboard/UploadImagesPage'
import DownloadFilesPage from '../dashboard/DownloadFilesPage'
import {CurrentPriceLevel} from '../dashboard/CurrentPriceLevel'
import ItemPage from '../dashboard/ItemPage'
import SearchItems from '../dashboard/SearchItems'

import CollectionPage from '../dashboard/CollectionPage';
import PreviewItemPage from '../reports/PreviewItemPage';
import GeneratePresentationPage from '../reports/GeneratePresentationPage';

import PriceCalculator from '../dashboard/PriceCalculator';

import NameGameHome from '../name_game/NameGameHome';
import NameGameLeaderboard from '../name_game/NameGameLeaderboard';

import ReportsHome from '../reports/ReportsHome';
import ExportProductGrid from '../reports/ExportProductGrid';
import ECommOverview from '../reports/ECommOverview';
import {ECommProducts} from '../reports/ECommProducts';

import TaggerLeaderboard from '../tagger/TaggerLeaderboard';

import UsersPage from '../auth/UsersPage';
import UserInfoPage from '../auth/UserInfoPage';

import OrdersPage from '../orders/OrdersPage';

import {CustomerSearchPage} from '../customers/CustomerSearchPage';
import CustomerInfo from '../customers/CustomerInfo';
import NewCustomerPage from '../customers/NewCustomerPage';
import CustomerServiceProfile from '../customers/CustomerServiceProfile';
import {PresentationListPage} from '../presentations/PresentationPage';

import {ShippingCalculator} from '../shipping/ShippingCalculator';

import {CreateItemReportPage} from '../item_reports/CreateItemReport';
import {ItemReportPage, EditItemReportPage} from '../item_reports/ItemReportView';
import {ItemReportListPage} from '../item_reports/ItemReportList';

import {EdiFilePage} from '../edi/EdiFileView';
import {EdiFileListPage} from '../edi/EdiFileList';

import {CommentsFeed} from '../comments/CommentsFeed';

const NavLink = ({match, display, url, className}) => (
  <li className={classNames("nav-item", className)}>
    <Link className={classNames("nav-link", {active: url === match.url})} to={url}>{display}</Link>
  </li>
)

const InternalSidebar = ({match}) => (
  <ul className="nav flex-sm-column">
    <NavLink match={match} display="Customers" url="/internal/customers"/>
    <NavLink match={match} display="Users" url="/internal/users"/>
    <NavLink match={match} display="Orders" url="/internal/orders"/>
    <NavLink match={match} display="Reports" url="/internal/reports"/>
    <NavLink match={match} display="Product Grid" url="/internal/product-grid"/>
    <NavLink match={match} display="Presentations" url="/internal/presentations"/>
    <NavLink match={match} display="Upload Assets" url="/internal/upload-images" className="mt-sm-4"/>

    <NavLink match={match} display="Downloads" url="/internal/downloads"/>
    <NavLink match={match} display="Calculator" url="/internal/calculator"/>
    <NavLink match={match} display="Internal Search" url="/internal/search"/>
    <NavLink match={match} display="Customer Service" url="/internal/customer-service"/>
    <NavLink match={match} display="eComm Sales" url="/internal/ecomm-sales"/>
    <NavLink match={match} display="eComm Products" url="/internal/ecomm-products"/>

    <NavLink match={match} display="Create Item Report" url="/internal/item-reports/create" className="mt-sm-4"/>
    <NavLink match={match} display="View Item Reports" url="/internal/item-reports"/>

    <NavLink match={match} display="EDI Files" url="/internal/edi-files" className="mt-sm-4"/>

    <NavLink match={match} display="All Comments" url="/internal/comments-feed" className="mt-sm-4"/>
  </ul>
)

const InternalRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.url}/reports`} component={ReportsHome}/>

    <Route path={`${match.url}/search`} component={SearchItems}/>

    <Route path={`${match.url}/item/:sku/preview`} component={PreviewItemPage}/>
    <Route path={`${match.url}/item/:sku`} component={ItemPage}/>


    {/*TODO: this breaks react 16 because it doesn't use the correct proptypes/etc*/}
    {/*<Route exact path={`${match.url}/tagger`} component={Tagger}/>*/}
    <Route path={`${match.url}/tagger/leaderboard`} component={TaggerLeaderboard}/>
    <Redirect from={`${match.url}/leaderboard`} to={`${match.url}/tagger/leaderboard`}/>

    <Route path={`${match.url}/upload-images`} component={UploadImagesPage}/>
    <Route path={`${match.url}/downloads`} component={DownloadFilesPage}/>

    <Route path={`${match.url}/customers/new`} component={NewCustomerPage}/>
    <Route path={`${match.url}/customers/:customer_id`} component={props => <CustomerInfo {...props} editable={true}/>}/>
    <Route path={`${match.url}/customers`} component={() => <CustomerSearchPage editable={true}/>}/>

    <Route path={`${match.url}/set-price-level`} component={CurrentPriceLevel}/>
    <Route path={`${match.url}/users/:user_id`} component={UserInfoPage}/>
    <Route path={`${match.url}/users`} component={UsersPage}/>

    <Route path={`${match.url}/orders`} component={OrdersPage}/>

    <Route path={`${match.url}/item-reports/create`} component={CreateItemReportPage}/>
    <Route path={`${match.url}/item-reports/:item_report_id/edit`} component={EditItemReportPage}/>
    <Route path={`${match.url}/item-reports/:item_report_id`} component={ItemReportPage}/>
    <Route path={`${match.url}/item-reports`} component={ItemReportListPage}/>

    <Route path={`${match.url}/edi-files/:edi_file_id`} component={EdiFilePage}/>
    <Route path={`${match.url}/edi-files`} component={EdiFileListPage}/>

    <Route path={`${match.url}/generate-presentation`} component={GeneratePresentationPage}/>

    <Route path={`${match.url}/calculator`} component={PriceCalculator}/>

    <Route path={`${match.url}/name-game`} component={NameGameHome}/>
    <Route path={`${match.url}/name-game/leaderboard`} component={NameGameLeaderboard}/>

    <Route path={`${match.url}/collection/:collection_name`} component={CollectionPage}/>
    <Route path={`${match.url}/product-grid`} component={ExportProductGrid}/>
    <Route path={`${match.url}/customer-service`} component={CustomerServiceProfile}/>
    <Route path={`${match.url}/ecomm-sales`} component={ECommOverview}/>
    <Route path={`${match.url}/ecomm-products`} component={ECommProducts}/>
    <Route path={`${match.url}/shipping-calculator`} component={ShippingCalculator}/>
    <Route path={`${match.url}/presentations`} component={props => <PresentationListPage editable={true}/>}/>

    <Route path={`${match.url}/comments-feed`} component={CommentsFeed}/>
  </Switch>
)

class InternalApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {user: UserStore.get_user()};
  }

  componentDidMount() {
    UserStore.on('change', this.setUserFromStore);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserFromStore);
  }

  setUserFromStore = () => {
    this.setState({user: UserStore.get_user()})
  }

  render() {
    const {match, history} = this.props;

    const {user} = this.state;

    if (!user) {
      return <div>Loading user...</div>
    }

    if (!user.is_active || user.user_type !== 'Employee' || user.rep_code) {
      history.push('/');
      return null;
    }

    return (
      <div>
        <PublicHeader />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-3 col-lg-2 mt-sm-5">
              <InternalSidebar match={match}/>
            </div>

            <div className="col-12 col-md-9 col-lg-8 py-md-3 pl-md-5">
              <InternalRoutes match={match}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InternalApp;
