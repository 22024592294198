import React from 'react';

import CustomPage from '../components/CustomPage';

class ProductVideos extends React.Component {
  render() {
    return <CustomPage page={'product-videos'}/>;
  }
}

export default ProductVideos;